$text-disabled-view: #484848;

.ant-modal.stepper {
	top: 45px;
	.header {
		margin-top: -8px;
		padding-bottom: 2px;
	}
	.ant-steps.ant-steps-horizontal.ant-steps-label-vertical.ant-steps-dot {
		padding-top: 8px;
		padding-bottom: 2px;
	}
}

.steps-container {
	margin-top: 14px;
	padding: 0 12px;
	padding-left: 0;
}

.disabled-map {
	opacity: 0.8;
	pointer-events: none;
	filter: grayscale(100%);
}

.third-step {
	width: 100%;

	.section {
		margin-top: 16px;
	}
	.section:first-child {
		margin-top: 0px;
	}
	.center-title {
		display: flex;
		align-items: center;
		margin-bottom: 6px;
		margin-top: 15px;
	}
	.center-title:first-child {
		margin-top: 0;
	}
	.title {
		margin-right: 10px;
		font-weight: 600 !important;
		font-size: 16px !important;
		color: #3e3e3e;
	}
	.button-container {
		display: flex;
		justify-content: flex-end;
	}
}

.stepper {
	.header {
		border-bottom: 1px solid #e9e9e9;
	}
	.title {
		margin-bottom: 8px;
		font-size: 20px;
		font-weight: 700;
		text-transform: capitalize;
	}
}

.map-container {
	position: relative;
	margin-bottom: 10px;

	.map-overlay {
		position: absolute;
		z-index: 10;
		width: -webkit-fill-available;
		height: -webkit-fill-available;
		background: rgb(0 0 0 / 53%);
		color: white;
	}
	.message {
		display: flex;
		justify-content: center;
		height: 100%;
		align-items: center;
		font-size: 20px;
		width: 62%;
		text-align: center;
		margin: auto;
	}
}

.driver-calendar {
	margin-top: 1rem;
	.center-title {
		margin-bottom: 0.8rem;
	}
}

.work-schedule-container {
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: center;

	.calendar {
		width: 22rem;
		border: 1px solid #f0f0f0;
		border-radius: 2px;
	}
	.days {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}
	.day {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	.day-name {
		width: 3rem;
	}
	.day-time {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
		display: flex;
	}
	.not-available {
		color: #a8a8a8;
	}
	.calendar-menu {
		display: flex;
		justify-content: flex-end;
		padding: 8px;
	}
	.months {
		width: 6rem;
	}
}

.driver-stepper,
.task-stepper {
	margin-top: 8px;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	width: 100%;
}

.driver-stepper,
.task-stepper {
	.ant-spin-nested-loading {
		width: 100%;
	}
	.ant-form-item-label > label {
		color: #454545;
		font-weight: 600;
	}
}

.stepper-footer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.stepper-footer > .ant-col.ant-col-xs-24.ant-col-sm-24.ant-col-md-5 {
	text-align-last: right;
}

.ant-col.driver_stepper-next.ant-col-xs-24.ant-col-sm-24.ant-col-md-5, .ant-col.driver_stepper-create.ant-col-xs-24.ant-col-sm-24.ant-col-md-5 {
	display: contents;
}

.ant-col.driver_stepper-prev.ant-col-xs-24.ant-col-sm-24.ant-col-md-5 {
	margin-right: 12px;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
	color: #D92D2D;
}

.review-steps-container {
	height: 58vh;
	overflow-y: auto;
	padding: 12px;
	padding-top: 0;
	padding-bottom: 0px;

	.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
		display: none;
	}

	.ant-input-affix-wrapper-disabled .ant-input[disabled], .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-picker-input>input[disabled], .ant-input[disabled] {
		color: $text-disabled-view;
	}
}

.hr {
	flex-grow: 1;
	height: 1px;
	background-color: #cfcfcf;
	width: auto;

	&-space {
		margin: 6px;
	}
}

.form-margin {
	.ant-form-item {
		margin: auto;
	}
}

.third-step.review-steps-container {
	padding-right: 16px;
    padding-left: 0;
}

@media only screen and (max-width: 767px) {
	.third-step .title {
		color: #00AADE;
	}
	.hr {
		background-color: #00AADE;
	}
	.stepper-footer {
		flex-direction: column;
		height: fit-content;
		gap: 12px;
	}
	.review-steps-container {
		height: auto;
	}
	.ant-col.driver_stepper-prev.ant-col-xs-24.ant-col-sm-24.ant-col-md-5 {
		margin: 0;
	}
}
