.videoWrapper{
	min-height: 50vh;
	position: relative;
	.controller{
		text-align: center;
		padding-bottom: 5px;
	}
	.eventMedia{
		.img{
			max-height: 100vh;
			max-width: 100%;
			margin: 0 auto;
			display: block;

		}
	}
	.ant-result{
		position: absolute;
		padding: 0;
		display: block;
		top: calc(50% - 46px);
		width: 100%;
	}
}