$base-padding: 20px;

.driver-task-card,
.route-user-card,
.task-card {
	width: 100%;
	&.ant-card-hoverable {
		border: 1px solid rgba(217, 217, 217, 0.7019607843);
		box-shadow: 0px 3px 10px -6px rgba(0, 0, 0, 0.22);
		&:hover {
			border: 1px solid #7abfff;
			box-shadow: 0px 2px 12px -4px #8f8f8f;
		}
	}
	.ant-card-body {
		padding: 12px 15px;
	}
	.route-user-card-info {
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		[class^='icon-'],
		[class*=' icon-'],
		[class^='anticon-'],
		[class*=' anticon-'] {
			color: #1890ff;
			font-size: 14px;
			font-weight: 400;
		}
		&-label {
			font-size: 13px;
			font-weight: 300;
			color: #757575 !important;
			font-family: system-ui;
		}
	}
}

.route-user-card-info {
	.ant-space-item:first-child {
		color: #000000d9;
    	font-size: 14px;
	}
}

.device-card-title {
	.route-user-card-info-label.detail {
		color: #000000d9 !important;
		font-size: 14px;
		font-weight: 500;
		font-family: system-ui;
	}
}

.task-card {
	.ant-card-head {
		border: none;
		padding: 0 15px;
	}
	.ant-card-body {
		padding: 0 30px 12px;
		padding-bottom: 15px;
	}
	div.title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 11rem;
	}
}

.device-card-title {
	.route-user-card-info {
		.ant-space-item:nth-child(2) {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 11rem;
		}
	}
}

.task-card-menu {
	[class^='icon-'],
	[class*=' icon-'],
	[class^='anticon-'],
	[class*=' anticon-'] {
		color: #1890ff;
		font-size: 14px;
		font-weight: 400;
	}
	.card-status-container {
		margin-left: 10px;
		.task-detail-address {
			margin-bottom: 10px;
		}
		&.children {
			margin-bottom: 10px;
		}
	}

}

.card-status-container {
	margin-left: 10px;
}
.driver-task-card {
	.ant-card-body {
		padding: 12px;
	}
	&-menu {
		cursor: pointer;
		position: absolute;
		right: 12px;
		top: 12px;
		color: #d9d9d9;
		font-size: 16px;
	}
}

@keyframes fadeIn {
	from {
		max-height: 0;
		opacity: 0;
	}
	to {
		opacity: 1;
		max-height: 80vh;
	}
}
@keyframes fadeOut {
	from {
		opacity: 1;
		max-height: 80vh;
	}
	to {
		opacity: 0;
		max-height: 0;
	}
}

.ant-typography h4, div.ant-typography-h4, div.ant-typography-h4>textarea, h4.ant-typography {
	font-size: 14px;
    margin-top: 6px;
}
.floating-driver-task-list {
	.ant-card.route-user-card {
		border: 1px solid #e1e1e1;
		margin-bottom: 12px;
	}
}

.task-info-window {
	&.container {
		background-color: #FFF;
		padding: 0px 15px 15px 15px;
		width: 323px;
		height: auto;
		border-radius: 4px;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		display: flex;
		flex-direction: column;
	}
	.header {
		&.container {
			flex: 0.5;
			display: flex;
			align-items: center;
		}
		.content {
			display: flex;
			justify-content: space-between;
			padding-top: 15px;
			align-items: center;
			width: 100%;
			height: 100%;
			margin: 0;
		}
		.task-name {
			font-weight: bold;
			font-size: 18px;
			overflow: hidden;
		}
		.close {
			font-size: 14px;
			cursor: pointer;
		}
	}
	.divider {
		margin: 0;
	}
	.actions {
		&.container {
			flex: 0.5;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
			margin-top: 14px;
		}	
		.icon{
			font-size: 20px;
			margin-left: 25px;
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			&:hover::before {
				color: #1890FF;
			}
		}
	}
	.task-details {
		&.container {
			display: flex;
			flex-direction: column;
			flex: 2.5;
			margin-top: 5px;
		}
		.detail{
			&.content {
				display: flex;
				height: 100%;
				width: 100%;
				margin: 0;
				justify-content: space-between;
				margin-top: 10px;
			}
			.icon {
				display: flex;
				flex: 0.1;
				justify-content: center;
				height: 100%;
				font-size: 18px;
				padding-left: 0;
				padding-top: 0;
			}
			.icon-description {
				font-size: 24px;
			}
			.information {
				display: flex;
				flex: 1;
				flex-direction: column;
				justify-content: center;
				height: 100%;
				padding-left: 5px;
				overflow: hidden;
				gap: 2px;
			}
		}
	}
}
