.SharedMap {
    .ant-card {
        .ant-card-bordered {
            padding: 0;
        }        
        .ant-card-body {
            padding: 0;
        }
    }
}
.sharedSpin{
    z-index: 99999;
    position: absolute!important;
    height: calc(100vh - 15px);
    width: calc(100vw - 10px);
    background-color:  #ededed;
    .ant-spin-dot{
        top: calc(50vh - 20px);
    }
}