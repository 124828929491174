.date-picker {
    &.container {
        display: flex;
        flex: 1;
    }
    &.content {
        width: -webkit-fill-available;
        height: 100%;
        margin: 0;
        pointer-events: initial;
        border-radius: 6px;
        display: flex;
        align-items: center;
        background-color: #ffffff;
        box-shadow: none;
        border: 1px solid #1990ff;
        padding: 3px 0px;
        justify-content: center;
        gap: 8px;
        padding-left: 6px;
    }
    .icon-toolbarcalendar {
        color: #1990ff;
    }

    .ant-picker-input {
        width: fit-content;
    }
    .ant-picker-input>input {
        font-weight: bold;
        color: #1990ff;
        cursor: pointer;
        width: 8.5rem;
        flex: none;
    }

    .ant-picker.ant-picker-borderless.ant-picker-input {
        padding: 0;
    }
}