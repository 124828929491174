.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.icon-name {
  font-size: 32px;
  color: red;
}
========================================== */

.icon-map {
  width: 1.28125em;
}

.icon-strategy {
  width: 0.71875em;
}

.icon-volume-high {
  width: 1.0625em;
}

.icon-users {
  width: 1.125em;
}

.icon-truck {
  width: 1.0357142857142856em;
}

.icon-automobile {
  width: 1.1428571428571428em;
}

.icon-paper-plane-o {
  width: 1.0005714285714284em;
}

.icon-motorcycle {
  width: 1.3019285714285713em;
}

.icon-battery {
  width: 1.2857142857142856em;
}

.icon-battery-3 {
  width: 1.2857142857142856em;
}

.icon-battery-2 {
  width: 1.2857142857142856em;
}

.icon-battery-1 {
  width: 1.2857142857142856em;
}

.icon-battery-0 {
  width: 1.2857142857142856em;
}

.icon-thermometer {
  width: 0.5714285714285714em;
}

.icon-thermometer-3 {
  width: 0.5714285714285714em;
}

.icon-thermometer-2 {
  width: 0.5714285714285714em;
}

.icon-thermometer-1 {
  width: 0.5714285714285714em;
}

.icon-thermometer-0 {
  width: 0.5714285714285714em;
}

.icon-search2 {
  width: 0.9285714285714285em;
}

.icon-user2 {
  width: 0.7142857142857142em;
}

.icon-close {
  width: 0.7857142857142857em;
}

.icon-trash-o {
  width: 0.7857142857142857em;
}

.icon-ban {
  width: 0.8571428571428571em;
}

.icon-group {
  width: 1.0714285714285714em;
}

.icon-circle {
  width: 0.8571428571428571em;
}

.icon-paper-plane-o2 {
  width: 1.0005714285714284em;
}

.icon-podcast {
  width: 0.8482142857142857em;
}
