$carColors: (
    "ed1b24": #ed1b24, "e91e63": #e91e63,
    "9c27b0": #9c27b0, "673ab7": #673ab7,
    "3f51b5": #3f51b5, "2196f3": #2196f3,
    "03a9f4": #03a9f4, "00bcd4": #00bcd4,
    "009688": #009688, "4caf50": #4caf50,
    "8bc34a": #8bc34a, "cddc39": #cddc39,
    "ffeb3b": #ffeb3b, "ffc107": #ffc107,
    "ff9800": #ff9800, "ff5722": #ff5722,
    "795548": #795548, "607d8b": #607d8b
);

@each $label, $color in $carColors {
    .car-color-#{$label} {
        background-color: $color;
    }
}