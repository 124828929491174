.maintenance{
    .maintenaceCard {
		margin: 10px 0;
		.description {
			padding-left: 5px;
			color: #1890ff;
			cursor: pointer;
			font-size: 12px;
		}		
		.unitDeplay {
			text-align: center;
			padding: 0 0 10px 0;
		}
		.alertIcons {
			padding: 0;
			text-align: center;
		}
		.disabledDelete{
			color: rgba(0, 0, 0, 0.25);
		}
		.iconCar{
			width: 21px;
			&.iconCarRed{
				color:#f5222d;
			}
		}
	}
    .newMaintenance{
		width: 100%;
		height: 200px;
		margin-top: 10px;
	}
}
.maintenanceForm{
	.deviceFormRow{
		margin-top: 10px;
		&.deviceList{
			padding: 5px;
			overflow-y: scroll;
			height: 200px;
		}
	}
}

.active {
	color: #52c41a!important;
}