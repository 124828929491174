.country_flag {
	width: 20px;
	display: inline-block;
	margin-right: 5px;

	&.country_ac {
		height: 10px;
		background-position: 0px 0px;
	}
	&.country_ad {
		height: 14px;
		background-position: -22px 0px;
	}
	&.country_ae {
		height: 10px;
		background-position: -44px 0px;
	}
	&.country_af {
		height: 14px;
		background-position: -66px 0px;
	}
	&.country_ag {
		height: 14px;
		background-position: -88px 0px;
	}
	&.country_ai {
		height: 10px;
		background-position: -110px 0px;
	}
	&.country_al {
		height: 15px;
		background-position: -132px 0px;
	}
	&.country_am {
		height: 10px;
		background-position: -154px 0px;
	}
	&.country_ao {
		height: 14px;
		background-position: -176px 0px;
	}
	&.country_aq {
		height: 14px;
		background-position: -198px 0px;
	}
	&.country_ar {
		height: 13px;
		background-position: -220px 0px;
	}
	&.country_as {
		height: 10px;
		background-position: -242px 0px;
	}
	&.country_at {
		height: 14px;
		background-position: -264px 0px;
	}
	&.country_au {
		height: 10px;
		background-position: -286px 0px;
	}
	&.country_aw {
		height: 14px;
		background-position: -308px 0px;
	}
	&.country_ax {
		height: 13px;
		background-position: -330px 0px;
	}
	&.country_az {
		height: 10px;
		background-position: -352px 0px;
	}
	&.country_ba {
		height: 10px;
		background-position: -374px 0px;
	}
	&.country_bb {
		height: 14px;
		background-position: -396px 0px;
	}
	&.country_bd {
		height: 12px;
		background-position: -418px 0px;
	}
	&.country_be {
		width: 18px;
		height: 15px;
		background-position: -440px 0px;
	}
	&.country_bf {
		height: 14px;
		background-position: -460px 0px;
	}
	&.country_bg {
		height: 12px;
		background-position: -482px 0px;
	}
	&.country_bh {
		height: 12px;
		background-position: -504px 0px;
	}
	&.country_bi {
		height: 12px;
		background-position: -526px 0px;
	}
	&.country_bj {
		height: 14px;
		background-position: -548px 0px;
	}
	&.country_bl {
		height: 14px;
		background-position: -570px 0px;
	}
	&.country_bm {
		height: 10px;
		background-position: -592px 0px;
	}
	&.country_bn {
		height: 10px;
		background-position: -614px 0px;
	}
	&.country_bo {
		height: 14px;
		background-position: -636px 0px;
	}
	&.country_bq {
		height: 14px;
		background-position: -658px 0px;
	}
	&.country_br {
		height: 14px;
		background-position: -680px 0px;
	}
	&.country_bs {
		height: 10px;
		background-position: -702px 0px;
	}
	&.country_bt {
		height: 14px;
		background-position: -724px 0px;
	}
	&.country_bv {
		height: 15px;
		background-position: -746px 0px;
	}
	&.country_bw {
		height: 14px;
		background-position: -768px 0px;
	}
	&.country_by {
		height: 10px;
		background-position: -790px 0px;
	}
	&.country_bz {
		height: 14px;
		background-position: -812px 0px;
	}
	&.country_ca {
		height: 10px;
		background-position: -834px 0px;
	}
	&.country_cc {
		height: 10px;
		background-position: -856px 0px;
	}
	&.country_cd {
		height: 15px;
		background-position: -878px 0px;
	}
	&.country_cf {
		height: 14px;
		background-position: -900px 0px;
	}
	&.country_cg {
		height: 14px;
		background-position: -922px 0px;
	}
	&.country_ch {
		width: 15px;
		height: 15px;
		background-position: -944px 0px;
	}
	&.country_ci {
		height: 14px;
		background-position: -961px 0px;
	}
	&.country_ck {
		height: 10px;
		background-position: -983px 0px;
	}
	&.country_cl {
		height: 14px;
		background-position: -1005px 0px;
	}
	&.country_cm {
		height: 14px;
		background-position: -1027px 0px;
	}
	&.country_cn {
		height: 14px;
		background-position: -1049px 0px;
	}
	&.country_co {
		height: 14px;
		background-position: -1071px 0px;
	}
	&.country_cp {
		height: 14px;
		background-position: -1093px 0px;
	}
	&.country_cr {
		height: 12px;
		background-position: -1115px 0px;
	}
	&.country_cu {
		height: 10px;
		background-position: -1137px 0px;
	}
	&.country_cv {
		height: 12px;
		background-position: -1159px 0px;
	}
	&.country_cw {
		height: 14px;
		background-position: -1181px 0px;
	}
	&.country_cx {
		height: 10px;
		background-position: -1203px 0px;
	}
	&.country_cy {
		height: 14px;
		background-position: -1225px 0px;
	}
	&.country_cz {
		height: 14px;
		background-position: -1247px 0px;
	}
	&.country_de {
		height: 12px;
		background-position: -1269px 0px;
	}
	&.country_dg {
		height: 10px;
		background-position: -1291px 0px;
	}
	&.country_dj {
		height: 14px;
		background-position: -1313px 0px;
	}
	&.country_dk {
		height: 15px;
		background-position: -1335px 0px;
	}
	&.country_dm {
		height: 10px;
		background-position: -1357px 0px;
	}
	&.country_do {
		height: 14px;
		background-position: -1379px 0px;
	}
	&.country_dz {
		height: 14px;
		background-position: -1401px 0px;
	}
	&.country_ea {
		height: 14px;
		background-position: -1423px 0px;
	}
	&.country_ec {
		height: 14px;
		background-position: -1445px 0px;
	}
	&.country_ee {
		height: 13px;
		background-position: -1467px 0px;
	}
	&.country_eg {
		height: 14px;
		background-position: -1489px 0px;
	}
	&.country_eh {
		height: 10px;
		background-position: -1511px 0px;
	}
	&.country_er {
		height: 10px;
		background-position: -1533px 0px;
	}
	&.country_es {
		height: 14px;
		background-position: -1555px 0px;
	}
	&.country_et {
		height: 10px;
		background-position: -1577px 0px;
	}
	&.country_eu {
		height: 14px;
		background-position: -1599px 0px;
	}
	&.country_fi {
		height: 12px;
		background-position: -1621px 0px;
	}
	&.country_fj {
		height: 10px;
		background-position: -1643px 0px;
	}
	&.country_fk {
		height: 10px;
		background-position: -1665px 0px;
	}
	&.country_fm {
		height: 11px;
		background-position: -1687px 0px;
	}
	&.country_fo {
		height: 15px;
		background-position: -1709px 0px;
	}
	&.country_fr {
		height: 14px;
		background-position: -1731px 0px;
	}
	&.country_ga {
		height: 15px;
		background-position: -1753px 0px;
	}
	&.country_gb {
		height: 10px;
		background-position: -1775px 0px;
	}
	&.country_gd {
		height: 12px;
		background-position: -1797px 0px;
	}
	&.country_ge {
		height: 14px;
		background-position: -1819px 0px;
	}
	&.country_gf {
		height: 14px;
		background-position: -1841px 0px;
	}
	&.country_gg {
		height: 14px;
		background-position: -1863px 0px;
	}
	&.country_gh {
		height: 14px;
		background-position: -1885px 0px;
	}
	&.country_gi {
		height: 10px;
		background-position: -1907px 0px;
	}
	&.country_gl {
		height: 14px;
		background-position: -1929px 0px;
	}
	&.country_gm {
		height: 14px;
		background-position: -1951px 0px;
	}
	&.country_gn {
		height: 14px;
		background-position: -1973px 0px;
	}
	&.country_gp {
		height: 14px;
		background-position: -1995px 0px;
	}
	&.country_gq {
		height: 14px;
		background-position: -2017px 0px;
	}
	&.country_gr {
		height: 14px;
		background-position: -2039px 0px;
	}
	&.country_gs {
		height: 10px;
		background-position: -2061px 0px;
	}
	&.country_gt {
		height: 13px;
		background-position: -2083px 0px;
	}
	&.country_gu {
		height: 11px;
		background-position: -2105px 0px;
	}
	&.country_gw {
		height: 10px;
		background-position: -2127px 0px;
	}
	&.country_gy {
		height: 12px;
		background-position: -2149px 0px;
	}
	&.country_hk {
		height: 14px;
		background-position: -2171px 0px;
	}
	&.country_hm {
		height: 10px;
		background-position: -2193px 0px;
	}
	&.country_hn {
		height: 10px;
		background-position: -2215px 0px;
	}
	&.country_hr {
		height: 10px;
		background-position: -2237px 0px;
	}
	&.country_ht {
		height: 12px;
		background-position: -2259px 0px;
	}
	&.country_hu {
		height: 10px;
		background-position: -2281px 0px;
	}
	&.country_ic {
		height: 14px;
		background-position: -2303px 0px;
	}
	&.country_id {
		height: 14px;
		background-position: -2325px 0px;
	}
	&.country_ie {
		height: 10px;
		background-position: -2347px 0px;
	}
	&.country_il {
		height: 15px;
		background-position: -2369px 0px;
	}
	&.country_im {
		height: 10px;
		background-position: -2391px 0px;
	}
	&.country_in {
		height: 14px;
		background-position: -2413px 0px;
	}
	&.country_io {
		height: 10px;
		background-position: -2435px 0px;
	}
	&.country_iq {
		height: 14px;
		background-position: -2457px 0px;
	}
	&.country_ir {
		height: 12px;
		background-position: -2479px 0px;
	}
	&.country_is {
		height: 15px;
		background-position: -2501px 0px;
	}
	&.country_it {
		height: 14px;
		background-position: -2523px 0px;
	}
	&.country_je {
		height: 12px;
		background-position: -2545px 0px;
	}
	&.country_jm {
		height: 10px;
		background-position: -2567px 0px;
	}
	&.country_jo {
		height: 10px;
		background-position: -2589px 0px;
	}
	&.country_jp {
		height: 14px;
		background-position: -2611px 0px;
	}
	&.country_ke {
		height: 14px;
		background-position: -2633px 0px;
	}
	&.country_kg {
		height: 12px;
		background-position: -2655px 0px;
	}
	&.country_kh {
		height: 13px;
		background-position: -2677px 0px;
	}
	&.country_ki {
		height: 10px;
		background-position: -2699px 0px;
	}
	&.country_km {
		height: 12px;
		background-position: -2721px 0px;
	}
	&.country_kn {
		height: 14px;
		background-position: -2743px 0px;
	}
	&.country_kp {
		height: 10px;
		background-position: -2765px 0px;
	}
	&.country_kr {
		height: 14px;
		background-position: -2787px 0px;
	}
	&.country_kw {
		height: 10px;
		background-position: -2809px 0px;
	}
	&.country_ky {
		height: 10px;
		background-position: -2831px 0px;
	}
	&.country_kz {
		height: 10px;
		background-position: -2853px 0px;
	}
	&.country_la {
		height: 14px;
		background-position: -2875px 0px;
	}
	&.country_lb {
		height: 14px;
		background-position: -2897px 0px;
	}
	&.country_lc {
		height: 10px;
		background-position: -2919px 0px;
	}
	&.country_li {
		height: 12px;
		background-position: -2941px 0px;
	}
	&.country_lk {
		height: 10px;
		background-position: -2963px 0px;
	}
	&.country_lr {
		height: 11px;
		background-position: -2985px 0px;
	}
	&.country_ls {
		height: 14px;
		background-position: -3007px 0px;
	}
	&.country_lt {
		height: 12px;
		background-position: -3029px 0px;
	}
	&.country_lu {
		height: 12px;
		background-position: -3051px 0px;
	}
	&.country_lv {
		height: 10px;
		background-position: -3073px 0px;
	}
	&.country_ly {
		height: 10px;
		background-position: -3095px 0px;
	}
	&.country_ma {
		height: 14px;
		background-position: -3117px 0px;
	}
	&.country_mc {
		width: 19px;
		height: 15px;
		background-position: -3139px 0px;
	}
	&.country_md {
		height: 10px;
		background-position: -3160px 0px;
	}
	&.country_me {
		height: 10px;
		background-position: -3182px 0px;
	}
	&.country_mf {
		height: 14px;
		background-position: -3204px 0px;
	}
	&.country_mg {
		height: 14px;
		background-position: -3226px 0px;
	}
	&.country_mh {
		height: 11px;
		background-position: -3248px 0px;
	}
	&.country_mk {
		height: 10px;
		background-position: -3270px 0px;
	}
	&.country_ml {
		height: 14px;
		background-position: -3292px 0px;
	}
	&.country_mm {
		height: 14px;
		background-position: -3314px 0px;
	}
	&.country_mn {
		height: 10px;
		background-position: -3336px 0px;
	}
	&.country_mo {
		height: 14px;
		background-position: -3358px 0px;
	}
	&.country_mp {
		height: 10px;
		background-position: -3380px 0px;
	}
	&.country_mq {
		height: 14px;
		background-position: -3402px 0px;
	}
	&.country_mr {
		height: 14px;
		background-position: -3424px 0px;
	}
	&.country_ms {
		height: 10px;
		background-position: -3446px 0px;
	}
	&.country_mt {
		height: 14px;
		background-position: -3468px 0px;
	}
	&.country_mu {
		height: 14px;
		background-position: -3490px 0px;
	}
	&.country_mv {
		height: 14px;
		background-position: -3512px 0px;
	}
	&.country_mw {
		height: 14px;
		background-position: -3534px 0px;
	}
	&.country_mx {
		height: 12px;
		background-position: -3556px 0px;
	}
	&.country_my {
		height: 10px;
		background-position: -3578px 0px;
	}
	&.country_mz {
		height: 14px;
		background-position: -3600px 0px;
	}
	&.country_na {
		height: 14px;
		background-position: -3622px 0px;
	}
	&.country_nc {
		height: 10px;
		background-position: -3644px 0px;
	}
	&.country_ne {
		width: 18px;
		height: 15px;
		background-position: -3666px 0px;
	}
	&.country_nf {
		height: 10px;
		background-position: -3686px 0px;
	}
	&.country_ng {
		height: 10px;
		background-position: -3708px 0px;
	}
	&.country_ni {
		height: 12px;
		background-position: -3730px 0px;
	}
	&.country_nl {
		height: 14px;
		background-position: -3752px 0px;
	}
	&.country_no {
		height: 15px;
		background-position: -3774px 0px;
	}
	&.country_np {
		width: 13px;
		height: 15px;
		background-position: -3796px 0px;
	}
	&.country_nr {
		height: 10px;
		background-position: -3811px 0px;
	}
	&.country_nu {
		height: 10px;
		background-position: -3833px 0px;
	}
	&.country_nz {
		height: 10px;
		background-position: -3855px 0px;
	}
	&.country_om {
		height: 10px;
		background-position: -3877px 0px;
	}
	&.country_pa {
		height: 14px;
		background-position: -3899px 0px;
	}
	&.country_pe {
		height: 14px;
		background-position: -3921px 0px;
	}
	&.country_pf {
		height: 14px;
		background-position: -3943px 0px;
	}
	&.country_pg {
		height: 15px;
		background-position: -3965px 0px;
	}
	&.country_ph {
		height: 10px;
		background-position: -3987px 0px;
	}
	&.country_pk {
		height: 14px;
		background-position: -4009px 0px;
	}
	&.country_pl {
		height: 13px;
		background-position: -4031px 0px;
	}
	&.country_pm {
		height: 14px;
		background-position: -4053px 0px;
	}
	&.country_pn {
		height: 10px;
		background-position: -4075px 0px;
	}
	&.country_pr {
		height: 14px;
		background-position: -4097px 0px;
	}
	&.country_ps {
		height: 10px;
		background-position: -4119px 0px;
	}
	&.country_pt {
		height: 14px;
		background-position: -4141px 0px;
	}
	&.country_pw {
		height: 13px;
		background-position: -4163px 0px;
	}
	&.country_py {
		height: 11px;
		background-position: -4185px 0px;
	}
	&.country_qa {
		height: 8px;
		background-position: -4207px 0px;
	}
	&.country_re {
		height: 14px;
		background-position: -4229px 0px;
	}
	&.country_ro {
		height: 14px;
		background-position: -4251px 0px;
	}
	&.country_rs {
		height: 14px;
		background-position: -4273px 0px;
	}
	&.country_ru {
		height: 14px;
		background-position: -4295px 0px;
	}
	&.country_rw {
		height: 14px;
		background-position: -4317px 0px;
	}
	&.country_sa {
		height: 14px;
		background-position: -4339px 0px;
	}
	&.country_sb {
		height: 10px;
		background-position: -4361px 0px;
	}
	&.country_sc {
		height: 10px;
		background-position: -4383px 0px;
	}
	&.country_sd {
		height: 10px;
		background-position: -4405px 0px;
	}
	&.country_se {
		height: 13px;
		background-position: -4427px 0px;
	}
	&.country_sg {
		height: 14px;
		background-position: -4449px 0px;
	}
	&.country_sh {
		height: 10px;
		background-position: -4471px 0px;
	}
	&.country_si {
		height: 10px;
		background-position: -4493px 0px;
	}
	&.country_sj {
		height: 15px;
		background-position: -4515px 0px;
	}
	&.country_sk {
		height: 14px;
		background-position: -4537px 0px;
	}
	&.country_sl {
		height: 14px;
		background-position: -4559px 0px;
	}
	&.country_sm {
		height: 15px;
		background-position: -4581px 0px;
	}
	&.country_sn {
		height: 14px;
		background-position: -4603px 0px;
	}
	&.country_so {
		height: 14px;
		background-position: -4625px 0px;
	}
	&.country_sr {
		height: 14px;
		background-position: -4647px 0px;
	}
	&.country_ss {
		height: 10px;
		background-position: -4669px 0px;
	}
	&.country_st {
		height: 10px;
		background-position: -4691px 0px;
	}
	&.country_sv {
		height: 12px;
		background-position: -4713px 0px;
	}
	&.country_sx {
		height: 14px;
		background-position: -4735px 0px;
	}
	&.country_sy {
		height: 14px;
		background-position: -4757px 0px;
	}
	&.country_sz {
		height: 14px;
		background-position: -4779px 0px;
	}
	&.country_ta {
		height: 10px;
		background-position: -4801px 0px;
	}
	&.country_tc {
		height: 10px;
		background-position: -4823px 0px;
	}
	&.country_td {
		height: 14px;
		background-position: -4845px 0px;
	}
	&.country_tf {
		height: 14px;
		background-position: -4867px 0px;
	}
	&.country_tg {
		height: 13px;
		background-position: -4889px 0px;
	}
	&.country_th {
		height: 14px;
		background-position: -4911px 0px;
	}
	&.country_tj {
		height: 10px;
		background-position: -4933px 0px;
	}
	&.country_tk {
		height: 10px;
		background-position: -4955px 0px;
	}
	&.country_tl {
		height: 10px;
		background-position: -4977px 0px;
	}
	&.country_tm {
		height: 14px;
		background-position: -4999px 0px;
	}
	&.country_tn {
		height: 14px;
		background-position: -5021px 0px;
	}
	&.country_to {
		height: 10px;
		background-position: -5043px 0px;
	}
	&.country_tr {
		height: 14px;
		background-position: -5065px 0px;
	}
	&.country_tt {
		height: 12px;
		background-position: -5087px 0px;
	}
	&.country_tv {
		height: 10px;
		background-position: -5109px 0px;
	}
	&.country_tw {
		height: 14px;
		background-position: -5131px 0px;
	}
	&.country_tz {
		height: 14px;
		background-position: -5153px 0px;
	}
	&.country_ua {
		height: 14px;
		background-position: -5175px 0px;
	}
	&.country_ug {
		height: 14px;
		background-position: -5197px 0px;
	}
	&.country_um {
		height: 11px;
		background-position: -5219px 0px;
	}
	&.country_un {
		height: 14px;
		background-position: -5241px 0px;
	}
	&.country_us {
		height: 11px;
		background-position: -5263px 0px;
	}
	&.country_uy {
		height: 14px;
		background-position: -5285px 0px;
	}
	&.country_uz {
		height: 10px;
		background-position: -5307px 0px;
	}
	&.country_va {
		width: 15px;
		height: 15px;
		background-position: -5329px 0px;
	}
	&.country_vc {
		height: 14px;
		background-position: -5346px 0px;
	}
	&.country_ve {
		height: 14px;
		background-position: -5368px 0px;
	}
	&.country_vg {
		height: 10px;
		background-position: -5390px 0px;
	}
	&.country_vi {
		height: 14px;
		background-position: -5412px 0px;
	}
	&.country_vn {
		height: 14px;
		background-position: -5434px 0px;
	}
	&.country_vu {
		height: 12px;
		background-position: -5456px 0px;
	}
	&.country_wf {
		height: 14px;
		background-position: -5478px 0px;
	}
	&.country_ws {
		height: 10px;
		background-position: -5500px 0px;
	}
	&.country_xk {
		height: 15px;
		background-position: -5522px 0px;
	}
	&.country_ye {
		height: 14px;
		background-position: -5544px 0px;
	}
	&.country_yt {
		height: 14px;
		background-position: -5566px 0px;
	}
	&.country_za {
		height: 14px;
		background-position: -5588px 0px;
	}
	&.country_zm {
		height: 14px;
		background-position: -5610px 0px;
	}
	&.country_zw {
		height: 10px;
		background-position: -5632px 0px;
	}
}
