.diagnostics {
    display: flex;
    flex: 1;
    justify-content: center;
    padding-bottom: 30px;
    .smsDiagnostics {
        max-width: 1040px;
        display: flex;
        flex: 1;
        flex-direction: column;
        height: auto;
        gap: 20px;
        .sms-form {
            border-radius: 4px;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
            padding: 28px 16px;
            .sms-form-items {
                flex-grow: 2;
            }
            .btn-form {
                flex-grow: 1;
            }
        }

        .client-information-card {
            padding: 10px 20px;
            box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            .client-information-card-content {
                display: flex;
                flex-direction: row;
                column-gap: 40px;

                .client-information-card-content-item {
                    span {
                        font-size: 14px;
                    }
                    :nth-child(1) {
                        font-weight: 500;
                        margin-right: 5px;
                    }
                }
            }
        }

        .ant-divider-inner-text {
            font-weight: bold;
        }

        .diagnostic-card {
            .error-code {
                display: inline;
                font-weight: 800;
            }
            .description-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .last-message-date {
                    margin-left: 5px;
                    font-weight: 800;
                    text-transform: capitalize;
                }
            }
        }
    }
}