.request-video-form {
	width: 100%;
	text-align: center;
	&-container {
		width: 80%;
		gap: 12px !important;
	}
}

// sm
@media only screen and (max-width: 767px) {
	.multimedia-dialog {
		width: 100% !important;
		.ant-modal-body {
			background: #f7f7f7 !important;
		}
		.multimedia {
			&-event-list {
				height: calc((3 / 16) * 841px);
			}
			&-side {
				padding-bottom: 12px;
				padding-right: 0;
			}
			&-source {
				padding-top: 12px;
				padding-left: 0;
				&-info-date {
					text-align: left;
				}
			}
		}
		.multimedia-event-list {
			min-height: 168px;
		}
	}
}

// md
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.multimedia-dialog {
		width: 740px !important;
		.multimedia {
			&-event-list {
				height: calc((2 / 16) * 841px);
			}
			&-side {
				padding-bottom: 12px;
				padding-right: 0;
			}
			&-source {
				padding-top: 12px;
				padding-left: 0;
				&-info-date {
					text-align: left;
				}
				&-media {
					min-height: 380px;
				}
			}
		}
		.multimedia-event-list {
			min-height: 168px;
		}
	}
}

// lg
@media only screen and (min-width: 992px) {
	.multimedia-dialog {
		width: 943px !important;
		height: 595px;
		.multimedia {
			&-event-list {
				height: calc((6 / 16) * 595px);
			}
			&-source-media {
				img {
					min-height: 380px;
				}
			}
		}
	}
}

// xxl
@media only screen and (min-width: 1200px) {
	.multimedia-dialog {
		height: 595px;
		width: 1100px !important;
		.multimedia {
			&-event-list {
				height: calc((7 / 16) * 595px);
			}
		}
	}
}

.multimedia,
.multimedia-live {
	.multimedia-dialog {
		min-height: 490px;
		.ant-modal-content {
			background: #f7f7f7;
		}
	}
	&-event-label {
		padding-bottom: 12px;
	}
	&-side {
		padding-right: 12px;
		&-container {
			width: 100%;
			&-spinner {
				max-height: none !important;
				.ant-spin-dot {
					font-size: 70px;
					margin: -35px !important;
				}
			}
			&-date-picker,
			&-event-filter {
				width: 100%;
			}
		}
	}
	&-event-list {
		width: 100%;
		overflow-y: scroll;
		scrollbar-width: none;
		&-empty {
			display: flex;
			justify-content: center;
			padding: 20px;
		}
		.multimedia-card {
			width: 100%;
			min-height: 54px;
			border: 1px solid #bfbfbf;
			border-radius: 2px;
			padding: 11px;
			background: #fff;
			cursor: pointer;
			&.selected {
				border: 1px solid #1890ff;
			}
			&-event-icon {
				img {
					width: 29px;
					height: 29px;
				}
			}
			&-info {
				padding-left: 11px;
			}
			&-event-collapse {
				text-align: right;
				margin: auto 0 auto auto;
			}
			&-multimedia {
				display: none;
				&.active {
					display: flex;
				}
				&-channel {
					width: 100%;
					padding: 8px 0 5px;
					&-divider {
						margin: 0;
					}
					&-label {
						font-size: 14px;
					}
					&-request {
						text-align: right;
					}
				}
			}
		}
		.multimedia-live-card {
			width: 100%;
			border: 1px solid #bfbfbf;
			border-radius: 2px;
			padding: 15px;
			background: #fff;
			cursor: pointer;
			&.selected {
				border: 1px solid #1890ff;
			}
			&-channel {
				width: 100%;
				padding: 15px 5px 5px;
				&-label {
					font-size: 14px;
				}
				&-request {
					text-align: right;
					button {
						width: 120px;
					}
				}
			}
		}
	}
	&-result {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		.ant-result-icon {
			margin: 0;
		}
		.result-icon {
			font-size: 100px;
		}
	}
	&-source {
		padding-left: 12px;
		&-info {
			padding: 10px 5px;
			font-size: 18px;
			&-label {
				display: inline-flex;
				align-items: center;
				gap: 5px;
				.icon-multimedia-video,
				.icon-multimedia-photo {
					font-size: 34px;
				}
			}
			&-date {
				text-align: center;
			}
			&-download {
				text-align: right;
				button {
					font-size: 18px;
					padding-right: 0;
				}
			}
		}
		&-paging {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 24px;
			gap: 45px;
		}
	}
}

.multimedia-live {
	&-video-container {
		width: 100%;
	}
	&-video-player-spinner {
		max-height: none !important;
		.ant-spin-dot {
			font-size: 70px;
			margin: -35px !important;
		}
	}
	.multimedia-live-layout {
		.multimedia-event-list {
			height: 100%;
			min-height: 225px;
		}
	}
}
.data-usage {
	&-card {
		height: 82px;
		width: 100%;
		font-size: 12px;
		border: 1px solid #bfbfbf;
		border-radius: 2px;
		padding: 8px;
		background: #fff;
		text-align: left;

		&-progress-bar {
			flex: 1;
			flex-wrap: nowrap;
			.ant-progress-outer {
				padding-right: 10px;
			}
		}
	}
	&-divider {
		margin: 12px 0 0;
		border-color: #d9d9d9;
	}
	&.usage-loading {
		.usage-loading-skeleton {
			margin-top: 3px;
		}
	}
	&.not-available {
		.not-available-message {
			&-icon {
				color: #1890ff;
			}
		}
	}
}
.multimedia-status {
	&-divider {
		border-color: #d9d9d9;
		margin-top: 4px;
		margin-bottom: 0px;
	}
	&-camera-title {
		margin-top: 8px;
		&-text {
			padding-left: 5px;
			color: #1890ff;
		}
	}
	&-sd-camera-card {
		margin-top: 8px;
		&-text {
			padding-left: 5px;
			color: #1890ff;
		}
	}
}

.multimedia-blocker {
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	display: flex;
	min-height: 388px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.multimedia-information {
	width: 20vw;
	.ant-popover-title {
		padding: 10px 16px;
	}
	.ant-popover-inner-content {
		padding: 0;
	}
	.ant-typography {
		padding: 0 15px;
	}
}
