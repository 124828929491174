.collapsible-window {
    background-color: #ffffff;
    position: fixed;
    bottom: 0;
    right: 0;

    // ant classes
    .ant-collapse-header {
        flex-flow: row-reverse;
    }
    .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
        transform: rotate(270deg);
    }
    .ant-collapse-expand-icon {
        margin-top: auto;
        margin-bottom: auto;
    }
    .window-buttons {
        display: flex;
        gap: 4px;
        width: 100%;
        span.anticon {
            padding: 5px;
        }
        span.anticon:hover {
            background: #e1e1e18f;
        }
    }

    button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only {
        width: inherit;
    }
}

.ant-collapse-extra > button {
    width: 100%;
    display: flex;
}