.client-admin-title {
    .link {
        color: #1890ff;
        cursor: pointer;
    }
}

th.ant-table-cell.devices-quote {
    text-align-last: center;
    text-transform: uppercase;

}

.client-view-modal {
    .ant-modal-body {
        height: 34rem;
        overflow-y: auto;
    }
    .ant-card.ant-card-bordered {
        border-radius: 6px;
    }
    &-section {
        .ant-card-body {
            padding-top: 16px;
        }
    }
    &-subtitle {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 4px;
        color: #353535;
    }
    &-text {
        .copy-text {
            margin-left: 10px;
            border-radius: 4px;

            .anticon svg {
                color: #808080;
            }
        }
    }
    &-measuring-system {
        margin-top: 25px;
    }
    &-space {
        display: flex;
    }
}