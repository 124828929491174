.ColorPicker {
	cursor: pointer;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	margin: 2px;
}
.mapConfig {
	.ant-row-flex {
		margin-bottom: 5px;
	}
	.rightCol {
		text-align: right;
	}
	.traceBySpeedSpinContainer {
		text-align: center;
		min-height: 90px;
		display: flex;
		align-items: center;
		justify-content: center;
		.traceBySpeedSpin {
			width: 100%;
		}
	}
}

.generalConfig {
	.ant-row-flex {
		margin-bottom: 10px;
	}

	.rightCol {
		text-align: right;
	}
	.language-row {
		margin-bottom: 10px;
	}
	.ant-radio-group {
		width: 100%;
	}
	.ant-radio-group-small .ant-radio-button-wrapper {
		width: 50%;
		text-align: center;
	}
}
.speedConfigError {
	text-align: center;
	color: #ff0202;
}
.configError {
	.listItem {
		.sliderConfigError {
			.ant-slider-track {
				background: #ff0202;
			}
			.ant-slider-handle {
				border-color: #ff0202;
				:hover {
					border-color: #ff0202;
				}
			}
		}
	}
	.newSlider {
		background: #dedede;
	}
}

.speedColorPicker {
	width: 20px;
	height: 20px;
}

.clickDisable {
	pointer-events: none;
}

.alingItems {
	.ant-list-item-action {
		li {
			vertical-align: middle !important;
		}
	}
}
