html, body, #root {
    height: 100%;
}

// route users
section.ant-layout.ant-layout-has-sider.mainLayout {
    height: 100%;
}
section.ant-layout, main.ant-layout-content.content {
    height: 100%;
}
.route-users-page-container, .view.driver-list.route-users-page-desktop-view, .route-users-page-container, .view.driver-list.route-users-page-desktop-view, .ant-col.ant-col-24.driver-table {
    height: 100%;
}
.ant-row.route-users-page-desktop-table-container {
    height: calc(100vh - 10rem);
}
.ant-col.ant-col-24.driver-table {
    .ant-table-wrapper {
        height: 100%;

        .ant-spin-nested-loading {
            height: 100%;
            .ant-spin-container {
                height: 100%;
                .ant-table {
                    height: 92%;
                    overflow-y: auto;
                    .ant-table-container {
                        height: 100%;
                    }
                }
                .ant-table.ant-table-fixed-header {
                    height: calc(100vh - 225px);
                    .ant-table-container {
                        height: 100%;
                        .ant-table-body {
                            height: 95%;
                        }
                    }
                }
            }
        }
    }
}

// Tasks
.view.task-page {
    height: 100%;

    .ant-row.ant-row-space-between.task-page-table-container {
        height: 96%;
    }
    .ant-col.ant-col-24, .ant-table-wrapper, .ant-spin-nested-loading, .ant-spin-container, .ant-table-container {
        height: 100%;
    }
    .ant-table.ant-table-fixed-header {
        height: 80%;
    }
    .ant-table {
        height: 90%;
    }
    .ant-table-container {
        overflow-y: scroll;
        max-height: 100%;
    }
    .ant-table-body {
        overflow-y: scroll;
        max-height: 90%;
    }

    form.ant-form.ant-form-horizontal {
        height: inherit;
    }
}

@media only screen and (max-height: 808px) {
    .view.task-page {
        .ant-row.ant-row-space-between.task-page-table-container {
            height: 82%;
        }
    }
}

@media only screen and (min-height: 1024px) {
    .view.task-page {
        .ant-row.ant-row-space-between.task-page-table-container {
            height: 85%;
        }
    }
}
