.filter-input {
	padding: 3px 12px;
	border: 1px solid #d3d3d3;
	width: 100%;
	display: flex;
	justify-content: space-between;
    height: auto;

	.ant-picker.ant-picker-small.ant-picker-borderless, input.ant-input.ant-input-sm.ant-input-borderless {
		width: 100%;
	}

    &-child-input {
		display: flex;
		align-items: center;
		gap: 4px;
		border: 1px solid #2d343b;
    	border-radius: 5rem;
    	padding: 0 8px;
    	color: #2d343b;
		padding-right: 2px;

		span {
			min-inline-size: fit-content;
		}
		input {
			border: 0;
			border-bottom: 1px solid #e6e6e6;
		}
		&-div {
			width: 100%;
		}
	}
    &-collapse {
		margin: 0;
		background: #f4f4f4;
		padding: 0.5rem 1rem;
	}
    &-dropdown {
		flex: 1;
		display: contents;
	}
    &-filter-count {
		color: #a9a9a9;
	}
	&-closed-pill {
		display: flex;
		gap: 0.5rem;
	}
    &-menu {
		width: 100%;
		display: flex;
		justify-content: space-between;
		flex-direction: row;
	}
	&-placeholder {
		color: #c6c6c6;
	}
	&-pill {
		background: #2d343b;
		color: #ffffff;
		padding: 0px 2px 0px 10px;
		border-radius: 1rem;
		gap: 11px;
		display: flex;
		align-items: center;
		font-size: 12px;
		height: 1.5rem;

		&-label {
			margin-right: 5px;
		}
		&-value {
			text-transform: capitalize;
		}
		span.ant-typography.ant-typography-ellipsis.ant-typography-single-line {
			color: white;
		}
		button {
			color: #ffffff;
		}
	}
    &-pills {
		display: flex;
		column-gap: 12px;
		flex-flow: wrap;
		align-items: center;
		width: 100%;
		max-height: 4rem;
		overflow: auto;
		row-gap: 6px;
	}
    &-right {
		display: flex;
		align-items: center;
		gap: 10px;
	}
	&-value {
		max-width: 100px;
	}
}

.filter-options {
	&-container {
		display: flex;
		align-items: flex-end;
		justify-content: space-around;
		gap: 6px;

		&-type {
			display: flex;
			flex-direction: row;
			color: #484848;
			align-items: flex-start;
			width: fit-content;

			.select {
				width: 100%;
			}
			span.ant-typography.ant-typography-ellipsis.ant-typography-single-line.ant-typography-ellipsis-single-line {
				text-overflow: ellipsis;
				width: 5rem;
			}
		}
	}
	.ant-select-arrow {
		background: #ffffff;
	}
}

@media only screen and (max-width: 767px) {
	.filter-input {
		&-pill {
			margin-right: 8px;
		}
		&-child-input {
			div {
				width: 40.2vw;
			}
		}
	}
}
