.horizontal-roles {
    .title {
        font-weight: 700;
        font-size: 14px;
    }
    .title.general {
        margin-bottom: 8px;
    }
    .ant-checkbox-group {
        display: flex !important;
        flex-flow: wrap;
        row-gap: 6px;
    }
    .advanced-roles {
        padding: 0px 18px;
    }
    span.ant-checkbox-inner {
        border-radius: 3px;
    }
    .section {
        border: 1px solid #e9e9e9;
        padding: 14px 14px;
        border-radius: 4px;
        margin-bottom: 16px;
    }
    .advanced-label {
        color: #b5b5b5;
        padding: 6px 12px;
        padding-top: 0;
    }
    .general {
        padding-top: 8px;
    }
    .switch {
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
    }
    .role-color {
        color: #282828;
    }
}

div#generalRoles {
    padding: 0px 10px;
    display: flex;
    align-items: flex-start;
    flex-flow: wrap;
    row-gap: 5px;
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
    border: 1px solid #1990ff;
    border-radius: 6px;
    padding-left: 4px;
    background: #1990ff;
    color: white;
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.ant-checkbox-group-item {
    border: 1px solid #dfdfdf;
    border-radius: 6px !important;
    padding-left: 4px;
}