@import url('https://fonts.cdnfonts.com/css/now');

$vendorPrefix: -webkit-;
                
.shareapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    padding-top: 14vh;

    &-actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-ios {
            img {
                padding-top: 20px;
                width: 9.5rem;
            }
        }
        &-andriod {
            img {
                padding-top: 20px;
                width: 10rem;
            }
        }
    }
    &-animation {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        div svg {
            width: 100% !important;
            height: 16rem !important;
            padding-left: 1rem;
        }
    }
    &-hero {
        font-size: 22px;
        font-weight: 700;
        background-color: #384b9c;
        background-size: 132%;
        #{$vendorPrefix}background-clip: text;
        background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
        font-family: system-ui;
        text-align: center;
        margin-bottom: 0.5rem;
        font-family: 'Now', sans-serif;
        line-height: 1.5;
    }
    &-icon {
        &-image, image {
            width: 10rem;
            object-fit: contain;
            padding: 22px;
            padding-bottom: 0;
            padding-top: 10px;
        }
    }
}

@media only screen and (min-height: 1000px) {
    .shareapp {
        &-animation {
            div svg {
                height: 42rem !important
            }
        }
    }
}

@media only screen and (max-height: 600px) {
    .shareapp {
        &-animation {
            bottom: -6rem;
        }
    }
}

@media only screen and (max-height: 500px) {
    .shareapp {
        &-animation {
            bottom: -12rem;
        }
    }
}

@media only screen and (max-height: 400px) {
    .shareapp {
        &-animation {
            bottom: -16rem;
        }
    }
}