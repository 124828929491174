.mapPage,
.mapMultiple {
	position: relative;
	.mapControl {
		position: absolute;
		z-index: 999;
		right: 0;
		height: auto;
		max-width: 300px !important;
		width: 300px !important;
		background: transparent;

		.thermometerContainer {
			margin-top: 10px;
			.thermometer {
				width: 50px;
				margin: auto;
				.thermometer__percent-current {
					display: none;
				}
				.thermometer__bg-color {
					background: linear-gradient(#f44336, #8bc34a, #2196f3);
				}
			}
			.thermometer--small {
				.thermometer__draw-a:after {
					bottom: -10px;
				}
				.thermometer__draw-b::before {
					background: #2196f3;
				}
				.thermometer__draw-b:after {
					width: 20px;
					height: 20px;
					background: #2196f3;
				}
			}
		}
		&.deviceSelected,
		&.fullHeight {
			height: 100%;
		}
		&.ant-layout-sider-collapsed {
			width: 0px !important;

			.ant-layout-sider-children {
				padding: 0px;
				background: #108ee9;
			}
		}
		.ant-layout-sider-children {
			padding: 2px 4px;
			background: #ffffff;
			box-shadow: -5px 3px 16px -8px rgba(0, 0, 0, 0.4);
			border-radius: 5px;
			border: 1px solid lightgray;

			.ant-tabs {
				padding: 5px;
			}
		}
		.MainControl {
			.ant-row-flex {
				padding: 10px 0;
			}
			.ant-card {
				margin: 5px 0px;
				.ant-calendar-picker {
					width: 100%;
				}
				&.info {
					.ant-row-flex {
						padding: 5px 0px;
					}
				}
				.ant-card-body {
					padding: 14px;
				}
				.right {
					text-align: right;
				}
			}
			.ctrPanelIcon {
				margin-top: 5px;
				.icon {
					font-size: 25px;
				}
			}
			.groups {
				margin-bottom: 10px;
			}
			.traceHistory {
				.listItem:hover {
					background: #f5f5f5;
				}
				.activeTrail {
					background: #f5f5f5;
				}
			}
		}
		.placesControl {
			.behaviorWrapper {
				.add,
				.action {
					margin: 10px 0;
					width: 100%;
				}

				.list {
					height: 54.5vh;

					&.tolerance {
						height: 46.5vh;
					}
				}
				.active {
					color: #52c41a;
				}
				.behavior-list {
					overflow-y: auto;
					height: 20rem;
					border: 1px solid #d9d9d9;
					border-radius: 2px;
				}
				.car-count {
					display: flex;
    				flex-direction: row;
    				align-items: center;
    				column-gap: 6px;
    				padding: 0px 4px;
    				border-radius: 4px;
				}
				.ant-list.ant-list-vertical.ant-list-sm.ant-list-split.ant-list-bordered.list.tolerance {
					border: 0;
				}
			}
			.ant-card {
				.ant-card-body {
					padding: 14px;
				}
			}
			.container {
				padding-top: 10px;
				.searchInput,
				.AddPlace {
					margin-bottom: 10px;
				}
			}
			.ant-layout-header {
				background: #f5f5f5;
				padding: 0px 10px;
			}
			.placesInfiniteScroll {
				border: 1px solid #e8e8e8;
				border-radius: 4px;
				overflow: auto;
				padding: 0px 10px;
				height: 72vh;
			}

			.list {
				width: 100%;
				.place {
					padding-left: 0px;
					padding-right: 0px;
					width: 16rem;
					.actions {
						text-align: right;
						padding-right: 2px;
						.action {
							padding-left: 0px;
							padding-right: 0px;
							color: #1990ff;
						}
					}
				}
			}

			.ant-form-item {
				margin-bottom: 0px;
			}

			.push-right {
				text-align: right;
			}
		}
		&.multiple {
			.multipleMapControlCollapse {
				max-height: 50vh;
				overflow-y: scroll;
			}
		}
		.pointsControl {
			.ant-card {
				.ant-card-body {
					padding: 14px;
				}
			}
		}
		.ant-tabs {
			overflow-y: auto;
			height: 100%;
			overflow-x: hidden;
			.ant-tabs-bar {
				margin-bottom: 0;
			}
		}
		.gadgets {
			text-align: center;
			.gadgetIcon {
				font-size: 25px;
				&.center {
					font-size: 45px;
				}
				&.batteyVoltage {
					font-size: 35px;
					color: #1890ff;
				}
			}
			.low {
				color: #ed1b24;
			}
			.med {
				color: #faad14;
			}
			.high {
				color: #1890ff;
			}
			.icon-battery-0,
			.zero {
				display: none;
			}
		}
		.traceHistory {
			height: 360px;
			overflow-y: scroll;
		}
	}
	.StepsCard {
		height: 100%;
		.ant-card-body {
			height: 100%;
			padding: 10px;
			.StepsWrapper {
				height: 100%;
				.route,
				.routePointsOfInterest,
				.fleet {
					height: calc(100% - 40px);
					overflow-y: auto;
				}
				.routeList {
					.route {
						padding: 5px;
						margin-bottom: 5px;
						&:hover {
							background: #e5e5e540;
						}
					}
					.routeDate {
						padding: 5px;
					}
					.actions {
						text-align: center;
						border-top: 0.1px solid #00000012;
					}
				}
				.route {
					.fleet {
						overflow-y: scroll;
						padding: 5px;
						max-height: 240px;
						min-height: 240px;
					}
					.capacityInput {
						.ant-input-number {
							width: 100%;
						}
					}
					.capacityBtn {
						text-align: center;
					}
				}
				.routePointsOfInterest {
					.poiList {
						overflow-y: scroll;
						max-height: calc(100% - 285px);
						min-height: 240px;
					}
				}
				.routeStops {
					height: 80%;
					.usedDevices,
					.visitsPois,
					.stops {
						overflow-y: scroll;
					}
					.usedDevices,
					.visitsPois {
						height: 20%;
						width: 100%;
					}
					.stops {
						height: 60%;
						.ant-list-item-meta-content {
							padding: 10px;
						}
						.selected {
							background: #e5e5e540;
							.eye {
								color: #6ec0ff;
							}
							.routePoints {
								width: 100%;
								padding: 10px;
							}
						}
						.actions {
							text-align: center;
							border-top: 0.1px solid #00000012;
						}
					}
				}
				.dropExist {
					position: absolute;
					z-index: 99999;
					margin: -20px 0;
				}
				.steps-content {
					height: calc(100% - 36px);
					.stepBody {
						height: calc(100% - 34px);
					}
					.stepsButtonsWrapper {
						position: absolute;
						bottom: 0;
						width: 100%;
						.stepsButtons {
							.ant-col {
								padding: 0 5px;
							}
							button {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.zero {
		display: none;
	}
	.collapser {
		padding: 16px 6px;
		position: absolute;
		display: block;
		margin: 40px 0px 0px -25px;
		background: white;
		font-weight: bold;
		cursor: pointer;
		box-shadow: -4px 6px 6px 0px rgb(10 10 10 / 14%);
		border-radius: 6px 0px 0px 6px;
		z-index: 980;
		left: -1px;
		border: 1px solid lightgray;
    	border-right: 0;

		i {
			font-size: 18px;
			padding: 10px 2px;
		}
	}
	.controlTab {
		background: white;
		z-index: 99999;
		.ant-select {
			width: 100%;
		}
	}
	.mapContent {
		background: #fff;
	}
	.emptyMaps {
		margin-top: 30vh;
		.ant-empty-image {
			font-size: 80px;
		}
	}
	.addDeviceIcon {
		opacity: 0.4;
	}
	.mapGrid {
		.gadgets,
		.description {
			position: absolute;
			z-index: 999;
			background: #fff;
			margin: 5px 10px;
			padding: 2px;
			right: 0;
			box-shadow: #0000004d 0px 1px 4px -1px;
		}
		.gadgets {
			text-align: center;
			margin-top: 35px;
			min-width: 40px;
			width: 10%;
			.gadgetIcon {
				font-size: 25px;
				&.center {
					font-size: 45px;
				}
			}
			.low {
				color: #ed1b24;
			}
			.med {
				color: #faad14;
			}
			.high {
				color: #1890ff;
			}
		}
	}
}
.temperatureIcon {
	color: red;
	margin-right: 5px;
	font-size: 16px;
}
.temperatureReportList {
	width: 25%;
	display: inline-block;
	text-align: center;
}

.inputWrapper {
	padding-top: 7px;
	display: none;
	.input {
		border: none;
		padding: 5px;
		width: 30vh;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei',
			'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
	}
	#nearestDevices{
		width: 30vh;
		background: #ffffff;
		margin-top: 10px;
		padding: 10px 2px;
		display: none;
		&.show{
			display: block;
		}
		.loader {
			border: 4px solid #f3f3f3;
			border-top: 4px solid #1890ff;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			animation: spin 2s linear infinite;
			margin: 0 auto;
		}

		@keyframes spin {
			0% { transform: rotate(0deg); }
			100% { transform: rotate(360deg); }
		}
		.title{
			width: 100%;
			text-align: center;
			font-size: 16px;
			border-bottom: 1px solid #f0f0f0;
			padding: 5px 12px;
			font-weight: bold;
			.closeSpam{
				float: right;
				cursor: pointer;
			}
		}
		.deviceOption{
			width: 100%;
			padding: 5px 12px;
			font-size: 14px;
			line-height: 22px;
			cursor: pointer;
			cursor: pointer;
			&:hover{
				background: #f5f5f5;
			}
			.shareIcon{
				float: right;
			}
		}
		.noDevicesDiv{
			width: 100%;
			font-size: 14px;
			padding: 5px;
			text-align: center;
		}
	}
}
.ctrPanelIcon {
	text-align: center;
}
#mapContainer_configuration {
	max-height: 300px;
}
.pointOfInterestTable {
	background: #fff;
	.table {
		height: 100%;
	}
	.icon {
		margin-right: 5px;
	}
	.ant-table-content {
		overflow-y: auto;
		height: calc(50vh - 160.5px);
		margin: 0 !important;
	}
	.advanceLink {
		color: #1890ff;
		cursor: pointer;
	}
	.poiToggle {
		width: 140px;
		height: 20px;
		z-index: 999;
		font-size: 12px;
		position: absolute;
		margin: 0 auto;
		right: calc(50vw - 70px);
		text-align: center;
		background: white;
		font-weight: bold;
		cursor: pointer;
		border: 1px solid #108ee9;
		box-shadow: 0px 2px 6px grey;
		.arrowToggle {
			margin-left: 3px;
		}
	}
}
.deviceInformationTable {
	background: #fff;
	.table {
		height: 100%;
	}
	.ant-table-content {
		overflow-y: auto;
		height: calc(40vh - 160.5px);
		margin: 0 !important;
	}
	.ant-table-row {
		cursor: pointer;
	}
	.poiToggle {
		width: 200px;
		height: 20px;
		z-index: 999;
		font-size: 12px;
		position: absolute;
		margin: 0 auto;
		right: calc(50vw - 70px);
		text-align: center;
		background: white;
		font-weight: bold;
		cursor: pointer;
		border: 1px solid #108ee9;
		box-shadow: 0px 2px 6px grey;
		.arrowToggle {
			margin-left: 3px;
		}
	}
}
.tagsContainer {
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	overflow: auto;
	padding: 8px 24px;
	height: 300px;
	margin: 10px 0;
	.tagItem {
		text-align: center;
	}
}
.pointOfInterestAdvanceFilter {
	max-width: 40vw;
	text-align: center;
	.advanceFilterWrapper {
		width: 100%;
	}
	.content {
		overflow-y: auto;
		.tagItem {
			margin: 5px 0;
		}
	}
}
.pointOfInterestControler {
	.tagList {
		max-height: 300px;
		overflow-y: scroll;
	}
}
.allTags {
	.tagItem {
		margin: 5px 0;
	}
}
.tagsView {
	.tagForm {
		padding: 5px 10px;
	}
	.tagsCount {
		margin: 10px 0;
		padding: 0 5px;
	}
	.tagsActions {
		margin: 10px 0;
		padding: 0 5px;
		color: #1890ff;
		.action {
			cursor: pointer;
		}
	}
	.demo-infinite-container {
		border: 1px solid #e8e8e8;
		border-radius: 4px;
		overflow: auto;
		padding: 8px 24px;
		height: 300px;
	}
	.demo-loading-container {
		position: absolute;
		bottom: 40px;
		width: 100%;
		text-align: center;
	}
	.options {
		padding: 5px;
	}
	.replaceAll {
		padding-bottom: 10px;
		.mergedCheckbox {
			color: #1890ff;
			cursor: pointer;
		}
	}
}

#contextMenu {
	width: 8vw;
	background: #ffffff;
	display: none;
	position: absolute;
	border-radius: 0 4% 4%;
	border: 1px solid #1890ff;
	z-index: 9999;
	.arrow-up {
		position: absolute;
		width: 10px;
		height: 10px;
		background: #ffffff;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		margin-top: -6px;
		margin-left: 1px;
		border-radius: 0 0 100%;
		border: 1px solid #1890ff;
		border-bottom: none;
		border-right: none;
	}

	.poiContextMenu {
		list-style: none;
		padding: 5px 0 10px;
		margin-bottom: 0;
		.label {
			font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB',
				'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
				'Segoe UI Symbol';
			text-transform: capitalize;
		}
		.poiContextItem {
			text-decoration: none;
			color: #575757;
			cursor: pointer;
			font-size: 16px;
			padding: 7px 20px;
			&:hover {
				background: #1890ff;
				color: #fff;
			}
		}
		.contextMenuIcon {
			font-size: 14px;
			padding-right: 5px;
		}
	}
}

.places-label {
	color: black;
	background-color: white;
	font-family: 'Roboto,Arial,sans-serif';
	font-size: 10px;
	font-weight: bold;
	text-align: center;
	border: 1px dashed black;
	padding: 2px 5px;
	white-space: nowrap;
	border-radius: 5px;
	opacity: 0.75;
	&.hideLabel{
	  display: none!important;
	}
}

.uploadTemp {
	margin-bottom: 0 !important;
}

.tankTooltip {
	margin: 0 !important;
}
.devicesSelect {
	.ant-select-selection {
		overflow: hidden;
	}
}

.deviceOption {
	.icon-showSensors {
		padding: 0 3px;
	}
}

.editTags {
	.TagList {
		overflow-y: scroll;
		max-height: 300px;
		margin-top: 10px;
		.ant-list-items {
			padding: 0 10px;
		}
	}
}

.sharePhotoModal {
	.img {
		max-height: 100vh;
		max-width: 100%;
		margin: 0 auto;
		display: block;
		padding-bottom: 10px;
	}
	.selectEmail {
		width: 100%;
	}
}

.shareNerest{
	.title{
		padding-top: 20px;
		font-size: 16px;
		span{
			margin-left: 5px;
		}
	}
	.shareBTN{
		padding: 0 0 20px;
    	margin: 10px 0;
		button{
			width: 100%;
			span{
				font-size: 20px;
			};
			&:disabled{
				margin-left: 0!important;
			}
		}
	}
	.comment{
		padding-top: 10px;
		.label:before {
			display: inline-block;
			margin-right: 4px;
			color: #ff4d4f;
			font-size: 14px;
			font-family: SimSun,sans-serif;
			line-height: 1;
			content: "*";
		}
	}
}

.cell-tower-label {
	background-color: #ffffffcc;
	padding: 3px;
	font-size: 12px;
	font-weight: bold;
	border: 1px solid #000;
	width: 180px;
	text-align: center;
}

.map-modal {
	.ant-modal-body {
		height: 30rem;
		overflow-y: auto;
	}
}
