.toolbar {
    width: 100%;
    background: #f4f4f4;
    border-radius: 12px 12px 0 0;
    padding: 0 0px;
    padding-top: 4px;
    padding-bottom: 5px;

    .tools {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: flex-start;
        padding: 0 14px;
        flex-direction: row;
        height: 24px;

        .left {
            display: flex;
            align-items: center;
            gap: 12px;
        }
        .toolbar-check {
            padding: 0 4px;
            color: #808080;
        }
        .tool-buttons {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .toolbar-button {
            padding: 0 8px;
            color: #808080;
        }
        span.icon-trash.icon {
            height: auto;
            width: auto;
        }
    }
    .message {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        background: #e8e8e8;
        padding: 4px 0px;

        .feedback {
            color: #808080;
            }
        .select-all-warn {
            color: #ff4d4e;
        }
        .select-all {
            color: #1a73e8;
        }
    }
}