$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$icon-mileage-per-state: unquote('"\\e9c1"');
$icon-multimedia-tutorial-path1: unquote('"\\e9bb"');
$icon-multimedia-tutorial-path2: unquote('"\\e9bc"');
$icon-multimedia-tutorial-path3: unquote('"\\e9bd"');
$icon-multimedia-tutorial-path4: unquote('"\\e9be"');
$icon-multimedia-tutorial-path5: unquote('"\\e9bf"');
$icon-multimedia-tutorial-path6: unquote('"\\e9c0"');
$icon-sort: unquote('"\\e9ba"');
$icon-completed-report: unquote('"\\e9b9"');
$icon-multimedia-path1: unquote('"\\e9ae"');
$icon-multimedia-path2: unquote('"\\e9af"');
$icon-multimedia-path3: unquote('"\\e9b1"');
$icon-multimedia-path4: unquote('"\\e9b2"');
$icon-multimedia-path5: unquote('"\\e9b3"');
$icon-multimedia-path6: unquote('"\\e9b4"');
$icon-multimedia-path7: unquote('"\\e9b5"');
$icon-multimedia-path8: unquote('"\\e9b7"');
$icon-multimedia-path9: unquote('"\\e9b8"');
$icon-unlink: unquote('"\\e9ad"');
$icon-pickup-pin: unquote('"\\e9aa"');
$icon-delivery-pin: unquote('"\\e9ab"');
$icon-edit: unquote('"\\e982"');
$icon-view: unquote('"\\e983"');
$icon-trash: unquote('"\\e9a7"');
$icon-description: unquote('"\\e9a8"');
$icon-task: unquote('"\\e9a9"');
$icon-layers: unquote('"\\e981"');
$icon-toolbarcalendar: unquote('"\\e980"');
$icon-share-round-path1: unquote('"\\e97e"');
$icon-share-round-path2: unquote('"\\e97f"');
$icon-direct-link: unquote('"\\e97c"');
$icon-text-message: unquote('"\\e97d"');
$icon-otp-access-code: unquote('"\\e979"');
$icon-multimedia-video-path1: unquote('"\\e99e"');
$icon-multimedia-video-path2: unquote('"\\e99f"');
$icon-multimedia-video-path3: unquote('"\\e9a0"');
$icon-multimedia-video-path4: unquote('"\\e9a1"');
$icon-multimedia-photo-path1: unquote('"\\e9a2"');
$icon-multimedia-photo-path2: unquote('"\\e9a3"');
$icon-multimedia-photo-path3: unquote('"\\e9a4"');
$icon-multimedia-photo-path4: unquote('"\\e9a5"');
$icon-multimedia-photo-path5: unquote('"\\e9a6"');
$icon-multimedia-video-map-path1: unquote('"\\e984"');
$icon-multimedia-video-map-path2: unquote('"\\e985"');
$icon-multimedia-video-map-path3: unquote('"\\e987"');
$icon-multimedia-video-map-path4: unquote('"\\e988"');
$icon-multimedia-video-map-path5: unquote('"\\e989"');
$icon-multimedia-map-path1: unquote('"\\e98a"');
$icon-multimedia-map-path2: unquote('"\\e98b"');
$icon-multimedia-map-path3: unquote('"\\e98c"');
$icon-multimedia-map-path4: unquote('"\\e98d"');
$icon-multimedia-map-path5: unquote('"\\e98e"');
$icon-multimedia-map-path6: unquote('"\\e98f"');
$icon-multimedia-map-path7: unquote('"\\e990"');
$icon-multimedia-map-path8: unquote('"\\e991"');
$icon-multimedia-map-path9: unquote('"\\e992"');
$icon-multimedia-map-path10: unquote('"\\e993"');
$icon-multimedia-map-path11: unquote('"\\e994"');
$icon-multimedia-map-path12: unquote('"\\e995"');
$icon-multimedia-photo-map-path1: unquote('"\\e996"');
$icon-multimedia-photo-map-path2: unquote('"\\e997"');
$icon-multimedia-photo-map-path3: unquote('"\\e998"');
$icon-multimedia-photo-map-path4: unquote('"\\e999"');
$icon-multimedia-photo-map-path5: unquote('"\\e99b"');
$icon-multimedia-photo-map-path6: unquote('"\\e99c"');
$icon-multimedia-photo-map-path7: unquote('"\\e99d"');
$icon-inactivity: unquote('"\\e974"');
$icon-route-type: unquote('"\\e975"');
$icon-optimize: unquote('"\\e97a"');
$icon-deallocate: unquote('"\\e97b"');
$icon-active: unquote('"\\e971"');
$icon-pin: unquote('"\\e972"');
$icon-tasks: unquote('"\\e973"');
$icon-driver: unquote('"\\e970"');
$icon-in-progress: unquote('"\\e96d"');
$icon-route-reports: unquote('"\\e96e"');
$icon-schedule-truck: unquote('"\\e96f"');
$icon-evening: unquote('"\\e96a"');
$icon-morning: unquote('"\\e96b"');
$icon-afternoon: unquote('"\\e96c"');
$icon-speedBingReport: unquote('"\\e969"');
$icon-add-device: unquote('"\\e967"');
$icon-email: unquote('"\\e966"');
$icon-world: unquote('"\\e965"');
$icon-activateDeactivateSimCard: unquote('"\\e964"');
$icon-drivers: unquote('"\\e963"');
$icon-idle: unquote('"\\e961"');
$icon-fuel-control: unquote('"\\e960"');
$icon-sukarneTrips: unquote('"\\e95f"');
$icon-police-station: unquote('"\\e95e"');
$icon-battery-voltage-1: unquote('"\\e95c"');
$icon-battery-voltage-2: unquote('"\\e95d"');
$icon-malfunction-indicador: unquote('"\\e95b"');
$icon-automaticReport: unquote('"\\e95a"');
$icon-currentState: unquote('"\\e959"');
$icon-media: unquote('"\\e957"');
$icon-driving: unquote('"\\e904"');
$icon-eventsReports: unquote('"\\e90d"');
$icon-fuel: unquote('"\\e932"');
$icon-geofences: unquote('"\\e933"');
$icon-loginHistory: unquote('"\\e934"');
$icon-parking: unquote('"\\e935"');
$icon-photos: unquote('"\\e936"');
$icon-pointOfInterest: unquote('"\\e937"');
$icon-sentCommand: unquote('"\\e938"');
$icon-speed: unquote('"\\e939"');
$icon-temperature: unquote('"\\e93a"');
$icon-unitLogReport: unquote('"\\e93b"');
$icon-unitsPerClients: unquote('"\\e93c"');
$icon-admin: unquote('"\\e93d"');
$icon-clients: unquote('"\\e93e"');
$icon-dashboard: unquote('"\\e93f"');
$icon-googleEarth: unquote('"\\e940"');
$icon-groups: unquote('"\\e941"');
$icon-maintenance: unquote('"\\e943"');
$icon-map: unquote('"\\e946"');
$icon-multipleMap: unquote('"\\e949"');
$icon-notification: unquote('"\\e94a"');
$icon-reports: unquote('"\\e94b"');
$icon-sensors: unquote('"\\e94c"');
$icon-services: unquote('"\\e94d"');
$icon-sims: unquote('"\\e94e"');
$icon-unitLog: unquote('"\\e94f"');
$icon-units: unquote('"\\e950"');
$icon-user: unquote('"\\e951"');
$icon-weeklyMap: unquote('"\\e952"');
$icon-weeklyRoute: unquote('"\\e953"');
$icon-cold: unquote('"\\e92f"');
$icon-hot: unquote('"\\e930"');
$icon-friday: unquote('"\\e928"');
$icon-monday: unquote('"\\e929"');
$icon-saturday: unquote('"\\e92a"');
$icon-sunday: unquote('"\\e92b"');
$icon-thursday: unquote('"\\e92c"');
$icon-tuesday: unquote('"\\e92d"');
$icon-wednesday: unquote('"\\e92e"');
$icon-broom: unquote('"\\e926"');
$icon-architecture: unquote('"\\e924"');
$icon-bed: unquote('"\\e91f"');
$icon-placeholder-path1: unquote('"\\e920"');
$icon-placeholder-path2: unquote('"\\e921"');
$icon-placeholder-path3: unquote('"\\e922"');
$icon-police-officer: unquote('"\\e923"');
$icon-coffee: unquote('"\\e917"');
$icon-sensor: unquote('"\\e913"');
$icon-units1: unquote('"\\e90e"');
$icon-units2: unquote('"\\e90f"');
$icon-units3: unquote('"\\e910"');
$icon-units4: unquote('"\\e911"');
$icon-earth: unquote('"\\e90b"');
$icon-beer: unquote('"\\e903"');
$icon-rule: unquote('"\\e912"');
$icon-location-on-road: unquote('"\\e915"');
$icon-delivery: unquote('"\\e977"');
$icon-pickup: unquote('"\\e978"');
$icon-presentation: unquote('"\\e90a"');
$icon-flag: unquote('"\\e906"');
$icon-flag2: unquote('"\\e907"');
$icon-checkered-flag: unquote('"\\e954"');
$icon-arrow_up: unquote('"\\e955"');
$icon-arrow_down: unquote('"\\e956"');
$icon-drag_indicator: unquote('"\\e931"');
$icon-bar: unquote('"\\e91e"');
$icon-flight: unquote('"\\e916"');
$icon-my_location: unquote('"\\e90c"');
$icon-sim_card: unquote('"\\e900"');
$icon-battery_alert: unquote('"\\e19c"');
$icon-flash_off: unquote('"\\e3e6"');
$icon-filter: unquote('"\\e927"');
$icon-gas-station: unquote('"\\e91c"');
$icon-travel-bus: unquote('"\\e91d"');
$icon-price-tag: unquote('"\\e925"');
$icon-restaurant: unquote('"\\e918"');
$icon-volume-mute: unquote('"\\e908"');
$icon-volume: unquote('"\\e908"');
$icon-audio: unquote('"\\e908"');
$icon-player: unquote('"\\e908"');
$icon-volume-high: unquote('"\\e909"');
$icon-volume2: unquote('"\\e909"');
$icon-audio2: unquote('"\\e909"');
$icon-speaker: unquote('"\\e909"');
$icon-player2: unquote('"\\e909"');
$icon-truck2: unquote('"\\e9b0"');
$icon-radio-tower: unquote('"\\f031"');
$icon-pencil: unquote('"\\e905"');
$icon-phone: unquote('"\\e942"');
$icon-address-book: unquote('"\\e944"');
$icon-envelop: unquote('"\\e945"');
$icon-location: unquote('"\\e947"');
$icon-location2: unquote('"\\e948"');
$icon-mobile: unquote('"\\e958"');
$icon-floppy-disk: unquote('"\\e962"');
$icon-redo2: unquote('"\\e968"');
$icon-users: unquote('"\\e99a"');
$icon-user-tie: unquote('"\\e976"');
$icon-search: unquote('"\\e986"');
$icon-bin: unquote('"\\e9ac"');
$icon-switch: unquote('"\\e9b6"');
$icon-file-excel: unquote('"\\eae2"');
$icon-anchor: unquote('"\\e919"');
$icon-home: unquote('"\\e91a"');
$icon-basket: unquote('"\\e91b"');
$icon-truck: unquote('"\\f0d1"');
$icon-automobile: unquote('"\\f1b9"');
$icon-car: unquote('"\\f1b9"');
$icon-paper-plane-o: unquote('"\\f1d9"');
$icon-send-o: unquote('"\\f1d9"');
$icon-motorcycle: unquote('"\\f21c"');
$icon-battery: unquote('"\\f240"');
$icon-battery-4: unquote('"\\f240"');
$icon-battery-full: unquote('"\\f240"');
$icon-battery-3: unquote('"\\f241"');
$icon-battery-three-quarters: unquote('"\\f241"');
$icon-battery-2: unquote('"\\f242"');
$icon-battery-half: unquote('"\\f242"');
$icon-battery-1: unquote('"\\f243"');
$icon-battery-quarter: unquote('"\\f243"');
$icon-battery-0: unquote('"\\f244"');
$icon-battery-empty: unquote('"\\f244"');
$icon-thermometer1: unquote('"\\f2c7"');
$icon-thermometer-4: unquote('"\\f2c7"');
$icon-thermometer-full: unquote('"\\f2c7"');
$icon-thermometer-3: unquote('"\\f2c8"');
$icon-thermometer-three-quarters: unquote('"\\f2c8"');
$icon-thermometer-2: unquote('"\\f2c9"');
$icon-thermometer-half: unquote('"\\f2c9"');
$icon-thermometer-1: unquote('"\\f2ca"');
$icon-thermometer-quarter: unquote('"\\f2ca"');
$icon-thermometer-0: unquote('"\\f2cb"');
$icon-thermometer-empty: unquote('"\\f2cb"');
$icon-warning: unquote('"\\e901"');
$icon-warning2: unquote('"\\e902"');
$icon-search2: unquote('"\\f002"');
$icon-envelope-o: unquote('"\\f003"');
$icon-user2: unquote('"\\f007"');
$icon-check: unquote('"\\f00c"');
$icon-close: unquote('"\\f00d"');
$icon-remove: unquote('"\\f00d"');
$icon-times: unquote('"\\f00d"');
$icon-trash-o: unquote('"\\f014"');
$icon-ban: unquote('"\\f05e"');
$icon-mail-forward: unquote('"\\f064"');
$icon-share: unquote('"\\f064"');
$icon-group: unquote('"\\f0c0"');
$icon-users2: unquote('"\\f0c0"');
$icon-circle: unquote('"\\1f5cc"');
$icon-paper-plane-o2: unquote('"\\f1da"');
$icon-send-o2: unquote('"\\f1da"');
$icon-podcast: unquote('"\\f2ce"');
$icon-drivers-license: unquote('"\\e914"');

