$planner-btn-bg: #f2f2f2;
$panel-shadow: 5px 4px 12px -6px rgba(0, 0, 0, 0.4);
$collapse-title-font: 15px;

.floating-panel {
	position: absolute;
	z-index: 999;
	padding: 10px 6px;
	width: 285px;
	background: #fcfcfc;
	box-shadow: 0px 4px 4px #00000040;
	overflow: hidden;

	&.left {
		left: $base-padding;
		top: 50%;
		transform: translate(0%, -50%);
		&-top {
			left: $base-padding;
			top: $base-padding;
		}
		&-bottom {
			left: $base-padding;
			bottom: $base-padding;
		}
	}
	&.center {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	&.right {
		right: $base-padding;
		top: 50%;
		transform: translate(0%, -50%);

		&-top {
			right: $base-padding;
			top: $base-padding;
		}
		&-bottom {
			right: $base-padding;
			bottom: $base-padding;
		}
	}
	&.top {
		left: 50%;
		top: $base-padding;
		transform: translate(-50%, 0%);
	}
	&.bottom {
		left: 50%;
		bottom: $base-padding;
		transform: translate(-50%, 0%);
	}
	&.open {
		.floating-panel-body {
			max-height: 80vh;
			height: 80vh;
			opacity: 1;
			animation: fadeIn 0.5s;
		}
		.floating-panel-divider {
			display: block;
			margin: 12px 0 15px;
		}
	}
	.floating-panel-head {
		position: relative;
		font-family: 'Helvetica';
		font-style: normal;
		font-weight: 700;
		font-size: 16px;
		line-height: 16px;
		min-height: 14px;
		&.left-icon {
			padding-bottom: 20px;
			color: #8f8f8f;
			display: flex;
			align-items: center;
		}
		&-sub-title {
			color: #2c2c2c;
			margin-left: 10px;
			font-size: 16px;
			font-weight: 600;
			line-height: 16px;
		}
		.collapse {
			position: absolute;
			right: 0;
			color: #d9d9d9;
		}
	}
	.floating-panel-divider {
		display: none;
	}
	.floating-panel-body {
		animation: fadeOut 0.5s;
		max-height: 0;
		opacity: 0;
	}
}

.scheduler {
    &-navbar {
		top: 1rem;
		position: absolute;
		left: 0.4rem;
    }
}

.planner-toolbar {
	&.container {
		align-items: center;
		border-width: 10px;
		border-color: black;
		border-radius: 20px;
	}
	.content {
		width: 26rem;
		height: 100%;
		display: flex;
		justify-content: space-between;
		margin: 0;
	}

	.actions-layers {
		&.container {
			display: flex;
			flex: 1;
			flex-direction: row;
			justify-content: flex-end;
			gap: 12px;
		}
		.actions {
			&.container {
				border-radius: 5px;
				width: 105px;
			}
			&.content {
				width: 100%;
				height: 100%;
				border-radius: 6px;
				box-shadow: none;
				border-color: transparent;
			}
			&.content:hover {
				background-color: $planner-btn-bg;
				color: #000000;
			}
			&.button-container {
				display: flex;
				justify-content: center;
				align-items: center;
				color: #5D5D5D;
				border: 1px solid #E5E5E5;
			}
			&.disabled, .disabled:hover {
				color: #00000040;
				border-color: #d9d9d9;
    			background: #f5f5f5;
			}
			
			&.button-content {
				max-width: 100%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		.layers {
			&.container {
				width: 50px;
			}
			&.content {
				padding: 0;
				width: 100%;
				height: 100%;
				border-radius: 6px;
				background-color: #FFFFFF;
				box-shadow: none;
			}
			&.button-container {
				display: flex;
				justify-content: center;
				align-items: center;
				padding-top: 3px;
				border-color: transparent;
				color: #5D5D5D;
				border: 1px solid #E5E5E5;
			}
			&.button-container:hover {
				background-color: $planner-btn-bg;
				color: #000000;
			}
			
			&.button-content {
				max-width: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.icon-layers-content {
				font-size: 17px;
			}
		}
	}
}

.team-panel {
    width: 26rem;
    border-width: 10px;
    border-color: black;
	display: flex;
    flex-direction: column;
    gap: 0.5rem;
	margin-top: 12px;

	&-assign-footer {
		margin-top: 15px;
		justify-content: space-between;
		margin-top: 15px;
		display: flex;
		align-content: center;
		align-items: center;

		.total {
			font-size: 12px;
			color: darkgray;
		}
	}
	&-cards-container {
		display: flex;
		align-content: flex-start;
		gap: 8px;
		height: 54vh;
		overflow-y: auto;
		padding: 8px 16px;
		margin-bottom: 5px;
		padding-left: 6px;
		border-bottom: 1px solid #e7e7e7;
		border-radius: 4px;
		padding-top: 6px;
		margin-top: 6px;
		padding-right: 16px;
	}
	&-empty {
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #ededed;
		border-radius: 2px;
		align-content: center;
	}
	&-delete-modal {
		margin-right: 4px;
		font-style: italic;
	}
	.ant-radio-group {
		width: 100%;
	}
	.ant-radio-button-wrapper {
		width: 50%;
		text-align: center;
		padding: 0 4px;
	}
	.ant-form-item {
		margin: 0;
	}

	span.icon-toolbarcalendar {
		color: #999999;
	}
	.filter-message {
		color: #b0b0b0;
		padding-top: 2px;
		font-size: 12px;
		text-align: center;
	}
	.active-card {
		border: 1px solid rgb(222 222 222);
		.list-item-card .ant-card-body {
			background: #ffffffa3;
		}
	}
}

.scheduler-panel {
	.search-bar {
		display: flex;
		flex-wrap: nowrap;

		.input {
			flex-grow: 1;
			width: auto;
		}
		.button {
			flex-shrink: 0;
			border-left: 0;
		}
		.ant-btn:active, .ant-btn:focus {
			color: #000000d9;
			border-color: #d9d9d9;
		}
	}
	.task-form {
		.ant-ribbon.ant-ribbon-placement-end.list-item-ribbon {
			width: 5rem;
		}
	}
}

.team-members-tab {
	.selected-team-member {
		.ant-card-body {
			background: #ffffff;
		}
	}
}

.task-info-pin {
	width: 24rem;
	display: flex;
    flex-direction: column;
    justify-content: flex-start;

	&-header {
		font-size: 15px;
		font-weight: bold;
		padding: 4px 0;
		padding-top: 12px;
	}
	&-actions {
		margin-top: 1px;
		border-top: 1px solid rgba(0,0,0,.06);
		padding-top: 4px;
		display: flex;
		gap: 6px;

		button {
			padding: 0 5px;
		}
		.icon {
			font-size: 18px;
			width: auto;
			height: auto;
		}
	}
	.divider {
		margin: 0.5rem 0;
	}
	.container {
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}
}

.info-window-detail {
	&-icon-wrapper {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin-right: 10px;
	}
	&-icon {
		font-size: 15px;
		height: auto;
		width: auto;
		margin: 0;
		padding: 2px;
		color: #444444;
	}
	&-title {
		margin: 0;
		padding: 0;
		font-size: 14px;
		font-weight: bold;
		padding-bottom: 2px;
	}
	&-subtitle {
		width: 95%;
	}
}

span.anticon.task-list-icon {
	margin-right: 6px;
}
.team-member-task-list {
	.ant-collapse-extra {
		width: 100%;
		display: flex;
		align-items: center;
	}
	.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		align-items: center;
		display: flex;
		flex-direction: row;
	}
	.button-group {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
		padding-top: 12px;
	}
	&.container {
		position: fixed;
		top: 80px;
		width: 400px;
		right: 65px;
		box-shadow: $panel-shadow;
		z-index: 1;

		.collapsible-window {
			box-shadow: 5px -4px 12px -6px rgb(0 0 0 / 29%);
		}
		.header {
			span.ant-badge-status-dot {
				width: 9px;
				height: 9px;
				top: 0px;
				margin-right: 6px;
			}
			&.content {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				width: 100%;
				height: 100%;
				margin: 0;
				padding: 0;
				background: transparent;
				box-shadow: none;
				.text-name {
					font-weight: bold;
					max-width: 170px;
					font-size: $collapse-title-font;
					overflow: hidden;
				}
				.tasks-detail {
					margin-top: -4px;
				}
				span.ant-typography.text-tasks {
					font-weight: 400;
					font-size: 13px;
					overflow: hidden;
					margin-left: 1px;
					color: #7b7b7b;
				}
			}
		}
		.divider {
			margin: 0;
			background-color: #D1D1D1;
			margin-bottom: 10px;
		}
		.form {
			&.select-all-item {
				margin: 0;
			}
			&.actions-area {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				padding-top: 10px;
				padding-bottom: 10px;
				width: 100%;

				.unassigned-button {
					padding: 0px 10px 0 10px;
					height: 22px;
					border-radius: 2px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 14px;	
					gap: 3px;						
					.unassigned-icon {
						font-size: 18px;
					}
					.icon-unlink:before {
						color: black !important;
					}
				}
			}
			&.actions-container {
				padding: 0;
				margin: 0;
				border-radius: 0;
				flex-direction: row;
				display: flex;
				padding-left: 16px;
				gap: 5px;
				padding-right: 4px;

				.icon {
					height: 100%;
					font-size: 1.5em;
					padding-left: 0;
					padding-top: 0;
					display: flex;
					align-items: center;
					cursor: pointer;
					&:hover::before {
						color: #1890FF;
					}
				}
			}
			&.content {
				padding: 0;
				overflow-y: auto;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-content: flex-start;
				border-radius: 4px;
				margin: 0;
				margin-top: 10px;
				max-height: 20rem;
				min-height: 10rem;
				gap: 6px;
				padding-right: 6px;

				.ant-form-item {
					margin: 0;
					padding: 0;
				}
			}
		}
	}
}

button.ant-btn.ant-btn-text.form.unassigned-button {
	display: flex;
    align-items: center;
    gap: 4px;
    color: black;
    height: 24px;
	padding: 0;
	width: 100%;
}

.scheduler-markers {
    background-color: #000000a8;
    color: #ffffff !important;
    position: absolute;
    padding: 0 4px;
    bottom: 0px;
    transform: translate(-50%, -50%);
}

.control-panel {
	width: 20rem;
	border-width: 10px;
	border-color: black;
	border-radius: 4px;
	position: absolute;
	top: 0.4rem;
	left: 0.4rem;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	background: #ffffff;
	box-shadow: $panel-shadow;
	.tasks-label {
		margin-top: 10px;
	}
}

.team-members-collapse-window {
	.list-item-card .ant-card-body {
		background: #ffffff;
	}
	.ant-card-bordered {
		border: 1px solid #dedede;
	}
	.list-item-card .ant-card-body:hover, .ant-card-bordered:hover {
		background: #e4e4e44f;
	}
}


/* Tablets */
@media (max-height: 800px) {
	.team-panel {
		.team-panel-cards-container {
			height: 42vh;
		}
	}
}
