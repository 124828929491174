.infiniteScroll {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 0px 10px;
    height: 72vh;
    .list {
        width: 100%;
        .delivery {
            padding-left: 0px;
			padding-right: 0px;
        }
    }
}

.alertOutRoute {
    background-color: #faad14;
}

.trailListItem {
    max-width: 100%;
}

.card {
    width: 100%;
    height: 100%;
    .listItem {
        margin-bottom: 5px;
        cursor: pointer;
        &:hover {
            transition: all 0.2s ease-out;
            border: 1px solid #cccccc78;
            top: -4px;
            box-shadow: 0px 0px 999px 999px #ffffff7e;
            z-index: 500;
          }
    }
    
}

.progress {
    text-align: center;
}
