.unitAlerts,
.eventAlerts,
.placeAlerts {
	.eventCard,
	.deviceCard,
	.behavior-card {
		padding: 10px 10px;
		.active {
			color: #52c41a;
		}
		.titleCheckBox {
			padding-right: 5px;
		}
	}
	.configIcon {
		color: #1890ff;
		cursor: pointer;
		&:hover {
			font-weight: bold;
		}
	}
	.allConfig {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		.configIcon {
			padding-right: 5px;
		}
	}
	.ant-spin-nested-loading {
		min-height: 100px;
		height: 100%;
	}
}

.behavior-card {
	display: flex;
	flex-direction: column;
	row-gap: 6px;
	button.ant-btn.ant-btn-text {
		padding: 0px 12px;
	}
	.ant-col.ant-col-20.push-right {
		padding-right: 14px;
	}
}

.behavior-delete-btn {
	display: flex;
	align-items: center;
}

.alert-configuration-form {
	.daysContainer .days {
		padding-top: 0;
	}
	.checkBox {
		padding: 10px 0;
	}
	.icons {
		padding-right: 5px;
	}
	.phoneList {
		min-height: 40px;
		overflow-y: auto;
		margin-top: 10px;
		max-height: 9rem;

		.phoneItem {
			width: 100%;
		}
	}
	.ant-divider-horizontal {
		margin: 10px 0;
	}
	.ant-divider {
		border-top: 1px solid rgb(0 0 0 / 5%);
	}
	.iddle {
		.ant-picker {
			width: 100%;
		}
	}
	.space-bottom {
		margin-bottom: 5px;
	}
	.space-top {
		margin-top: 10px;
	}
}

.alert-form-footer {
	margin-top: 1rem;
	border-top: 1px solid #eeeeee;
	padding-top: 0.8rem;
	display: flex;
	gap: 15px;
	justify-content: flex-end;
}

.component-space {
	margin-bottom: 10px;
}

.place-alert-divider {
	border-bottom: 1px solid #d3d3d3ae;
	padding-bottom: 16px;
}

.place-alert-radio {
	label.ant-radio-wrapper {
		border: 1px solid #d3d3d3;
		padding: 3px 5px;
		border-radius: 4px;
		margin: 4px 4px;
	}
	label.ant-radio-wrapper.ant-radio-wrapper-checked {
		border: 1px solid #1990ff;
	}
}

.emailAlertConfig {
	padding-right: 5px;
	color: #52c41a !important;
}
.authorizeSchedule {
	overflow: auto;
	.scheduleList {
		max-height: 150px;
		overflow: auto;
		span.ant-tag {
			width: 100%;
		}
		.union,
		.delete,
		.add {
			display: flex;
			justify-content: center;
		}
	}
	.ant-col.add {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
.authorize-schedule-config {
	text-align: start;
	&-cards {
		width: 100%;
		overflow: hidden auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding: 5px;
		max-height: 260px;
		&-container {
			box-shadow: 0px 1px 7.2px 0px #86868640;
			padding: 17px;
			border-radius: 2px;
			&-weeks {
				text-align: end;
				&-delete {
					color: #afafaf;
				}
				&-day {
					text-transform: uppercase;
				}
			}
		}
	}
	&-error {
		padding-top: 17px;
	}
	&-add {
		padding: 17px 0;
	}
}

.notifications-radio {
	.ant-radio-wrapper.ant-radio-wrapper-checked.ant-radio-wrapper-in-form-item {
		border: 2px solid #1890ff;
	}
	.ant-radio-wrapper.ant-radio-wrapper-in-form-item {
		border: 2px solid #eeeeee;
		padding: 5px 14px;
		border-radius: 4px;
		width: 100%;
	}
	.ant-space-vertical {
		width: 100%;
	}
	.ant-radio-wrapper-disabled {
		border: 2px solid #eeeeee !important;
	}
}

.alert-configuration-form {
	.ant-form-item {
		margin: 0;
	}
}

.alert-speed {
	display: flex;
	justify-content: flex-start;
	.ant-form-item-control {
		width: 12.5rem !important;
	}
	.ant-input-number-input {
		text-align: right;
		padding-right: 34px;
	}
	input {
		width: 100%;
	}
}

.ant-modal.alert-modal {
	top: 50px;
}

.alerts-container {
	display: grid;
	grid-template-rows: auto 1fr auto;
	height: calc(100vh - 190px);

	.menu {
		padding-bottom: 6px;
		box-shadow: 0px 6px 15px -12px #0000001f;
		z-index: 10;
	}
	.scroll-cards {
		overflow-y: auto;
		overflow-x: hidden;
	}
}

.displacement-modal {
	.notification {
		margin-top: 12px;
	}
	.section-title {
		margin-bottom: 0;
	}
}

.temp-checks {
	.ant-row,
	.ant-row:after,
	.ant-row:before {
		justify-content: flex-end;
	}
}

.temp-container {
	.checks-headers {
		padding-left: 32px;
	}
}

li.ant-list-item.ant-list-item-no-flex.behavior-card {
	padding-right: 6px;
}

.maintenance-modal {
	padding-top: 15px;
	.ant-row.alert-configuration-form {
		row-gap: 10px;
	}
}
.save-mantainance {
	span.anticon.anticon-save {
		padding-right: 6px;
	}
}

.alert-modal,
.displacement-modal,
.maintenance-modal,
.place-alert-modal {
	.section-title {
		font-size: 18px;
		font-weight: 600;
		margin-bottom: 10px;
		color: #00488b;
		padding-top: 5;
	}
	.speed-container {
		width: 100%;
	}
	.title {
		font-size: 15px;
		font-weight: 500;
		color: #575757;
	}
	.subtitle {
		font-size: 12px;
		color: #808080;
		label {
			font-size: 12px;
			color: #808080;
		}
	}
	.sms-phone {
		padding: 0;
	}
	.checkBox {
		padding-top: 0;
	}
	.efficiency-input {
		display: flex;
	}
	.ant-modal .ant-form-item {
		margin-bottom: 0;
	}
	.sms-phone {
		padding: 0;
	}
}
