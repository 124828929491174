.client{
	.sync {
		font-size: 15px;
		cursor: pointer;
		.syncButton{
			marging-right: 5px;
		}
		.syncTag {
			padding-left: 5px;
		}
		&:hover {
			color: #1890ff;
			font-weight: bold;
		}
	}
}
.clientSyncData{
	.copyIcon {
		padding-left: 5px;
		color: #1890ff;
		cursor: pointer;
	}
	.right {
		text-align: right;
	}
	.column {
		padding: 10px 0px;
	}
	.loading {
		text-align: center;
	}
	.clientCreatedIcon {
		color: #52c41a;
		.successIcon{
			font-size: 40px;
		}
		.others{
			color: #1890ff;
			font-size: 15px;
			cursor: pointer;
		}
	}
	.clientCreatedloading{
		color: #1890ff;
		.loadingIcon{
			font-size: 40px;
		}
	}
	.clientCreatedIconError {
		color: #f5222d;
		.errorIcon{
			font-size: 40px;
		}
	}
}