.deviceInformation {
	.groups{
		width: 40%;
		margin: 10px auto;
		display: block;
	}
	.cardContainer{
		padding: 5px;
		.deviceInformationCard{
			height: 600px;
			overflow-y: scroll;
		}
	}
}