.map-devices {
    height: 100%;
    .container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .map-container {
        height: 100%;
        width: 100%;
    }
}
