@import url('https://fonts.googleapis.com/css2?family=Rubik+One:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.stripe {
	margin-bottom: 30px;

	&.desktop {
		margin: 20px 75px;
	}

	.title {
		font-size: 20px;
		margin-bottom: 30px;
	}

	.ant-card.info-card {
		border-color: #d3d3d3;
		border-radius: 14px;
		box-shadow: 0px 0px 3.0999999046325684px 3px #c6c6c640;
		.table {
			overflow: auto;
		}
	}

	.link {
		font-weight: normal;
		font-size: 14px;
	}

	.ant-card.billing-card {
		border-color: #d3d3d3;
		text-align: center;
		color: #1890ff;
		border-radius: 14px;
		width: 383px;
		height: 174px;
		font-size: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 0px 3.0999999046325684px 3px #c6c6c640;

		&.mobile {
			width: auto;
			height: auto;
			font-size: 14px;
		}

		.ant-space {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
		}
	}

	.ant-card.greeting-card {
		font-size: 24px;

		&.mobile {
			font-size: 14px;
		}
	}

	.ant-card.actions {
		text-align: end;

		.action-btn {
			width: 171px;
			height: 37px;
			border-radius: 8px;

			&.mobile {
				width: 125px;
				height: 30px;
			}
		}
	}

	.redirect-link {
		font-weight: normal;
		font-size: 20px;
		&.mobile {
			font-size: 12px;
		}
	}

	.route-info {
		font-size: 16px;

		&.mobile {
			font-size: 12px;
		}
	}

	.trial-text {
		color: #064693;
		font-family: Now;
		font-size: 24px;
		&.mobile {
			font-size: 10px;
		}
	}

	.optimus-link {
		font-weight: normal;
		font-size: 14px;
		&.mobile {
			font-size: 10px;
		}
	}

	.ant-collapse.ant-collapse-item.ant-collpase-header {
		padding: 0px;
	}

	.ant-card.device-mobile-card {
		width: 100%;
		.ant-card-head {
			padding: 0px 10px;
		}
		.ant-card-body {
			padding: 10px;
		}
	}
}

.seats {
	.col-modal-btn {
		text-align: end;
		margin-top: 10px;
	}

	.save-btn {
		border-radius: 14px;
		width: 100px;
		font-size: 16px;
		line-height: 20px;
		&.mobile {
			width: 100%;
		}
	}

	.ant-card.plan-card {
		border-color: #d3d3d3;
		box-shadow: 0px 0px 3.0999999046325684px 3px #c6c6c640;
		border-radius: 14px;

		&.selected {
			border-color: #1890ff;
			.ant-card-head {
				background-color: #1890ff;

				.ant-card-head-title {
					color: #ffffff;
				}
			}
			&.mobile {
				.ant-card-head-title {
					font-size: 20px;
				}
			}
		}

		.ant-card-head {
			background-color: #e1e1e1;
			border-top-left-radius: 14px;
			border-top-right-radius: 14px;

			.ant-card-head-title {
				color: #a8a8a8;
				font-size: 30px;
			}
		}
		&.mobile {
			.ant-card-head-title {
				font-size: 20px;
			}
			.ant-card-body {
				padding: 10px;
			}
		}

		.ant-card-body {
			padding: 20px;
		}
	}

	.check-icon {
		font-size: 24px;
		color: #ffffff;

		&.mobile {
			font-size: 14px;
		}
	}

	.ant-card.seats-card {
		border-color: #d3d3d3;
		box-shadow: 0px 0px 3.0999999046325684px 3px #c6c6c640;
		border-radius: 14px;

		.ant-card-meta-title {
			font-size: 20px;
		}

		.ant-card-meta-description {
			font-size: 12px;
		}

		&.mobile {
			.ant-card-meta-title {
				font-size: 14px;
			}
			.ant-card-meta-description {
				font-size: 10px;
			}
		}
	}

	.custom-input-number {
		width: 50px;
		.ant-input-number-input {
			text-align: center !important;
		}
	}

	.custom-typography {
		background: linear-gradient(90deg, #329aff 12.5%, #02376b 78%);
		box-shadow: 0px 2px 7.7px 0px #0838677d;
		border-radius: 14px;
		.trial-text {
			font-family: 'Rubik One', sans-serif;
			font-weight: 400;
			font-size: 55px;
			color: #ffffff;
			&.mobile {
				font-size: 24px;
			}
		}
	}
	.new-client-text {
		font-family: 'Roboto', sans-serif;
		font-weight: 700;
		font-style: normal;
		font-size: 50px;
		color: #1890ff;
		&.mobile {
			font-size: 22px;
		}
	}
	.included-plans-text {
		font-family: 'Roboto', sans-serif;
		font-weight: 400;
		font-style: italic;
		font-size: 50px;
		color: #1890ff;
		justify-content: center;
		&.mobile {
			font-size: 22px;
		}
	}
	.routes-title {
		font-size: 100px;
		color: #064693;
		font-family: Now;
		font-weight: 700;
		&.feedback {
			font-size: 48px;
		}
		&.mobile {
			font-size: 24px;
		}
	}
	.description-text {
		font-size: 22px;
		font-weight: 400;
		color: #7b7979;
		&.mobile {
			font-size: 11px;
			margin-bottom: 25px;
		}
	}
	.col-content-centered {
		display: flex;
		justify-content: center;
	}
	.tracked-members {
		font-family: 'Segoe UI';
		font-size: 18px;
		font-weight: 700;
		&.mobile {
			font-size: 14px;
		}
	}
	.seats-description {
		font-family: 'Segoe UI';
		font-size: 12px;
		font-weight: 400;
		color: #808080;
		&.mobile {
			font-size: 9px;
		}
	}
	.bold {
		font-weight: 700;
	}

	.ant-collapse-content > .ant-collapse-content-box {
		padding: 0 !important;
	}

	.ant-collapse {
		.ant-collapse-item {
			.ant-collapse-header {
				padding: 8px 0px;
			}
		}
	}

	.item-icon {
		color: #05a805 !important;
		margin-right: 5px;
		align-self: center;
	}

	.list-item {
		justify-content: start;
		margin-left: 10px;
	}
}

.seats-modal {
	.custom-ribbon {
		position: absolute;
		right: -32px;
		top: 32px;
		height: 32px;
		align-content: center;
		width: 260px;
		gap: 0px;
		border-radius: 2px 2px 0px 2px;
		text-align: center;
	}

	.billing-text {
		color: #064693;
		font-family: Now;
		font-size: 18px;
		&.mobile {
			font-size: 13px;
		}
	}
}

.checkout-result-text {
	font-size: 16px;
}

.pre-checkout-form,
.customer-support-container {
	text-align: center;
	&-title {
		color: #022865;
		text-align: center;
		font-family: Helvetica;
		font-size: 48px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	&-sub-title {
		color: #022865;
		text-align: center;
		font-family: Helvetica;
		font-size: 32px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	&-lottie {
		height: 249px;
		margin: 0 auto;
	}
	&-disclaimer {
		margin-top: 20px;
		margin-bottom: 10px;
		span {
			font-weight: bold;
		}
	}
	&-passwrod {
		width: 100%;
		max-width: 336px;
	}
	&-proceed {
		width: 251px;
		height: 63px;
		padding: 10px 50px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		flex-shrink: 0;
		font-weight: bold;
	}
}
.customer-support-container {
	.imageLogo {
		width: 210px;
		margin: 0 auto;
		display: block;
	}
}
.stripe-payment-history {
	&-pagination {
		text-align: right;
		padding: 10px 0;
	}
	&-payment-method {
		display: flex;
		justify-content: space-between;
		padding: 0 15px;
		&-brand {
			text-transform: uppercase;
		}
	}
}

.stripe-activation-page,
.stripe-activation-route-page {
	padding: 15px 15px;
	&-logo {
		padding-top: 10px;
		width: 210px;
		margin: 0 auto;
	}
}
