.user-form {
	padding: 10px 0;
	.footer {
		margin: 0;
		.ant-divider {
			margin-top: 15px;
			margin-bottom: 10px;
		}
		.actions {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}
}
