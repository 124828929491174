.syncRastreoOptimus{
    margin-top: 5px; 
    margin-right: 15px; 
    margin-left: 15px;
    .steps-content{
        min-height: 200px;
        margin-top: 16px;
        background-color: #fafafa;
        border: 1px dashed #e9e9e9;
        border-radius: 2px;
    }
    .steps-action{
        margin-top: 24px;
    }
}