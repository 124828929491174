/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0px 0px 20px -4px #bcbcbcde;
  border-radius: 10px;
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #989898;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a3a1a1;
}

@media only screen and (max-width: 767px) {
  ::-webkit-scrollbar {
    width: 6px !important;
  }
}