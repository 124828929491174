.options-control {
    .ant-row:not(:last-child) {
        margin-bottom: 10px;
    }
}

.w-full {
    width: 100%;
}

.mw-full {
    max-width: 100%;
}
