@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.subcription-form, .subscription-modal {
    .choose-plan {
        display: flex;
        gap: 8px;
    }
    .choose-plan-label {
        color: #1890ff;
    }
    .choose-plan-warning {
        color: #ff4d4f;
    }
    .plans-modal {
        .ant-modal-body {
            padding-bottom: 3.5rem;
        }
    }
}

.subscription-modal {
    label {
        margin-right: 10px;
    }
}

.dashcam-plans {
    font-family: 'Roboto', sans-serif;

    &-icon {
        display: flex;
        justify-content: center;

        svg {
            width: 8rem !important;
        }
    }
    &-header {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-bottom: 2.5rem;

        .title {
            color: #2897FF;
            margin: 0;
        }
        .subtitle {
            color: #808083;
            font-size: 14px;
        }
    }
    &-list {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        gap: 20px;

        .list-plans-card {
            box-shadow: 0px 4px 4px #b3b3b340;
            height: 15.5rem;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            width: 228px;

            .ant-card-head-title {
                font-family: "Roboto", sans-serif;
                color: white;
                font-size: 28px;
                font-weight: 500;
                padding: 0;
            }
            .ant-card-head {
                background: #fa8616;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 66px;
            }
            .ant-card-body {
                padding: 24px;
                height: -webkit-fill-available;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
            .card-subtitle {
                color: #000000d9;
                margin-bottom: 10px;
                font-size: 16px;
            }
            .card-description {
                color: #00000073;
            }
        }
    }   
}

/* Big Laptops */
@media only screen and (min-width: 1440px) and (max-width: 1919px) {}

/* Normal Laptops */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {}

/* iPads (Tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {}

/* Phones */
@media only screen and (max-width: 767px) {
    .plans-modal {
        .ant-modal-body {
            max-height: 30rem;
            overflow: auto;
        }
        .ant-modal-content {
            margin: 0 10px;
            padding: 7px;
        }
    }

    .dashcam-plans {
        &-header {
            .title {
                font-size: 26px;
            }
        }
        &-icon {
            svg {
                width: 5rem !important;
            }
        }
        &-list {
            .list-plans-card {
                width: 100%;
            }
        }
    }
}