.disclaimer {
    &-message {
        margin-bottom: 10px;
    }
    &-text {
        font-size: 12px;
        color: #4c4c4c;
    }
    &-button {
        font-size: 12px;
        background-color: transparent;
    }
}

.ant-alert.disclaimer-message {
    padding: 8px 18px;
}
