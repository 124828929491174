.list-item {
    &-card {
        .ant-card-body {
            padding: 0.5rem 0.5rem;
            padding-top: 0.5rem !important;
            background: #F2F2F2;
        }
        .ant-card-body:hover {
            background: #ffffff;
            cursor: pointer;
        }
    }
    &-container {
        align-items: center;
        gap: 12px;

        &-text {
            margin-left: 8px;
            padding-right: 25%;
        }
    }
    &-description {
        font-size: 12px;
    }
    &-icon {
        padding: 6px;
        width: 2.5rem;
        font-size: 26px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-avatar>img {
            object-fit: cover !important;
        }
    }
    &-ribbon {
        &-container {
            height: fit-content;
        }
        span.ant-ribbon-text {
            font-size: 10px;
        }
    }
    &-ribbon-text {
        max-width: 90%;
        color: white;
    }
    &-prefix {
        text-align: center;
    }
    &-title {
        font-weight: 700;
    }
}

.ant-ribbon.ant-ribbon-placement-end.list-item-ribbon {
    line-height: 18px;
    max-width: 5.4rem;
    text-align: -webkit-center;
    min-width: 2rem;
}
