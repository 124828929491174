.welcoming{
    height: 100%;
    text-align: center;
    .title, .goodIcon{
        line-height: 1.8;
        font-size: 30px;
        font-weight: bold;
    }
    .subTitle{
        color: #00000073;
        font-size: 14px;
        line-height: 1.6;
        text-align: center;
        padding-bottom: 20px;
    }
    .action{
        width: 200px;
    }
}