.integrations-device {
    .requirements {
        .ant-list-header {
            padding-bottom: 0px;
        }
        .requirement{
            padding: 0px;
            border: none;
        }
    }
}

.integrations{
    .integrationCard{
        width: 214px;
        .optimus-card-body{
            text-align: center;
        }
        .configuration{
            font-size: 20px;
            padding: 0;
            color: #00000040;
        }
        &.optimus-card-active{
            .configuration{
                color: #1890FF;
            }
        }
        .optimus-card-actions{
            .actionIcon{
                padding-right: 10px;
            }
        }
    }
}