.import-file {
    &-upload-section {
        height: fit-content;
    }
    &-icon {
        font-size: 36px;
        color: #b6b6b6;
    }
    &-upload-text {
        font-size: 14px;
        color: #858585;
    }
    &-upload-supported-file {
        color: #a6a6a6;
        font-size: 12px;
    }
    &-formats {
        font-size: 12px;
        color: #b6b6b6;
        padding: 2px;
    }
    &-upload-templates {
        margin-top: 12px;
        gap: 5px;
    }
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        .import-file-icon, .import-file-upload-text {
            color: #40a9ff;
        }
    }
    .ant-modal-body {
        padding-bottom: 2px;
        padding-top: 18px;
    }
    &-summary {
        &-title {
            font-size: 16px;
            font-weight: 500;
        }
        &-totals {
            border-top: 1px solid #dedede;
            border-bottom: 1px solid #dedede;
            padding: 20px 6px;
            margin: 18px 0px;
            color: #2a2a2b;
            width: 100%;
            padding-right: 8px;

            .sub-total {
                display: flex;
                align-items: center;
                gap: 12px;

                span.anticon.anticon-exclamation-circle {
                    color: #ff4d4f;
                }
                span.anticon.anticon-check {
                    color: #53c417;
                }
            }
        }
        &-download {
            padding: 10px 15px;
            background: #f5f5f5;
            border-radius: 4px;
            margin: 25px 0px;
            margin-top: 5px;
            /* border: 1px solid lightgray; */

            &-label {
                gap: 10px;
                display: flex;
                align-items: center;
                font-size: 14px;
            }
        }
    }
}
