.custom-divider {
    margin-bottom: 10px;

    &-title {
        font-size: 18px;
        font-weight: 500;
        color: #2f2f2f;
        margin: 0;
    }
    &-subtitle {
        font-size: 16px;
        font-weight: 400;
        color: #2f2f2f;
        margin: 0;
    }
    &-description {
        padding-left: 4px;
        padding-right: 4px;
        font-size: 12px;
        color: darkgray;
    }
    .ant-divider.ant-divider-horizontal {
        border-top: 1px solid #0000001c;
    }
}
