@import '../../../styles/constants';

.ant-steps-item-title {
	font-size: 12px !important;
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
	padding: 0 0 2px !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

.task-map {
	height: 400px;
}

.stepper-map {
	height: 210px;
}

.form-space {
	margin-bottom: 15px;
}

.scheduler {
	position: relative;
	width: 100%;
	height: 100%;

	&-map {
		height: 100%;
		width: 100%;
	}

	&-date-wrapper {
		position: absolute;
		left: 50%;
		top: 20px;
		transform: translate(-50%, 0%);

		.scheduler-date-picker {
			width: 194px;
		}
	}
}

.route-task {
	position: relative;
	width: 100%;
	height: 100%;

	&-map {
		height: 650px;
		width: 100%;
	}
	&-navbar {
		top: 1rem;
		position: absolute;
		left: 0.4rem;
    }
}

.button-col {
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-col {
	display: flex;
	align-items: center;
}

.general-title {
	text-align: center;
}

.area-code {
	width: 100%;
}

.task-form {
	width: 100%;

	.ant-form-item-label {
		font-family: Helvetica;
		font-size: 20px;
		line-height: 23px;
		letter-spacing: 0em;
		text-align: left;
	}
}

.ant-space.ant-space-vertical.task-confirmation-form-checkbox-space.checks {
	background: #f9f9f9;
	gap: 0px !important;
	.ant-space-item {
		padding-bottom: 8px;
		border-bottom: 1px solid #e0e0e0;
		margin-bottom: 8px;
	}
}

label.ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item.user-checks, label.ant-radio-wrapper.ant-radio-wrapper-in-form-item.user-checks {
	width: 100%;
	span.ant-checkbox, span.ant-radio {
		width: 16px;
	}
	span:nth-child(2) {
		width: 100%;
	}
}

.ant-space.ant-space-vertical.task-confirmation-form-checkbox-space.checks .ant-space-item:last-child {
	border-bottom: 0;
}

.user-checks {
	.ant-badge-status-success, .ant-badge-status-default {
		width: 10px;
		height: 10px;
	}

	.rows {
		display: flex;
		flex-direction: row;
	}

	.label {
		&.disabled {
			color: #00000040;
		}
		&.enabled {
			color: #696969;
		}
	}
	.status-badge {
		padding-top: 8px;
	}
}

.task-confirmation-form {
	.ant-modal .ant-form-item {
		margin-bottom: 2px !important;
	}
	&-checkbox-group,
	&-checkbox-space,
	&-checkbox {
		width: 100%;
		font-weight: 500;
		background: #ffffff;
	}

	&-checkbox-space {
		overflow-y: auto;
		overflow-x: hidden;
		max-height: 23vh;
		gap: 16px;
		padding: 12px;
		background: #f1f1f1;
		padding-bottom: 0;
	}

	&-checkbox-space .checks {
		background: red;
	}

	&-checkbox {
		background: white;

		.route-user-card-info {
			&-label {
				font-size: 14px;
				font-weight: 400;
				color: #757575 !important;
			}

			&-icon {
				color: #3b82f6;
				font-size: 15px;
			}
		}
	}
	&-users {
		padding: 13px;
		max-height: 130px;
		overflow-y: auto;
		border: 1px solid #dedede;
		margin: 15px 0;
		font-weight: normal;
		font-size: 14px;

		.icon-driver {
			color: #3b82f6;
		}
	}
	&-actions {
		margin-top: 10px;
	}
	&-not-assigned {
		font-size: 2rem;
	}
}

.assign-task {
	&-animation {
		height: 14rem;
		display: flex;
		align-items: center;
		justify-content: center;
		
		div {
			width: 12rem;
			padding-bottom: 36px;
		}
	}
	&-title {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 15px;
		flex-direction: column;
	}
	&-partially-assigned {
		display: flex;
		flex-direction: row;
	}
	&-number-success {
		font-size: 4rem;
		margin-top: -58px;
		line-height: 1;
	}
	&-number {
		margin-right: 4px;
	}
}

.task-success-view {
	display: flex;
	flex-direction: column;
	gap: 2px;
	padding: 10px 16px;
	padding-top: 18px;
	background: #d3d3d38f;
	margin-top: 15px;
	margin-bottom: 22px;

	max-height: 26vh;
    overflow-y: auto;

	&-drivers {
		padding-bottom: 10px;

		span.ant-typography.route-user-card-info-label.detail {
			font-weight: 500;
		}
	}

	&-drivers:last-child {
		border-bottom: 0;
	}

	&-drivers .count {
		margin-left: 10px;
	}

	.icon-driver {
		color: #3b82f6;
	}
}

.task-assign-form {
	margin-top: 25px;
	[class$='-actions'] {
		text-align: right;
	}
}

.task-driver-form {
	.ant-typography h4,
	div.ant-typography-h4,
	div.ant-typography-h4>textarea,
	h4.ant-typography {
		font-size: 16px;
		text-align: center;
	}

	&-optimization {
		margin-bottom: 14px;
	}
	&-disclaimer {
		margin-bottom: 20px;
	}
	&-select-all {
		margin-top: 0px;
		margin-bottom: -5px !important;	
	}
	&-user-selection {
		padding: 10px;
		background: #f9f9f9;
		margin: 10px 0;
		margin-top: 10px;

		&-disclamer {
			font-size: 12px;
			padding: 16px 0;

			.anticon {
				color: #1991ff;
				font-size: 24px;
			}
		}
	}

	&-consider-return-disclamer {
		color: #757575;
		font-size: 12px;
	}

	&-consider-return-form-item {
		margin: 0 !important;
	}
}

.floating-task-list {
	&-space {
		width: 100%;
	}

	&-menu {
		text-align: right;
		cursor: pointer;
	}

	&-container {
		margin-top: 10px;
	}

	.loader {
		display: flex;
		justify-content: center;
	}
}

.task-detail {
	&-title {
		padding-bottom: 5px;
	}

	&-address {
		padding-bottom: 8px;
	}
}

.task-page {
	&-actions {
		display: flex;
		gap: 1.5rem;
		justify-content: center;
	
		button {
			color: #AFAFAF;
		}
		button.ant-btn.ant-btn-text.ant-btn-icon-only:hover {
			color: #1990ff;
		}
	}
	&-red-bg {
		background-color: $unassigned-light-bg;
	}
	&-red-bg:hover {
		background-color: $unassigned-dark-bg;
	}
	.nav-row {
		display: flex;
		justify-content: flex-end;
		gap: 10px;
	}

	.nav-row:first-child {
		margin-right: 10pc;
	}

	.top-container {
		padding-bottom: 20px;
		display: flex;
		justify-content: space-between;
	}

	.stepper-footer {
		padding-top: 30px;
	}
	.ant-table-tbody>tr>td {
		border-bottom: 1px solid #ffffff;
	}
	.ant-tag-error {
		color: #F5222D;
		border-color: #FFA39E
	}
	.ant-tag-warning {
		color: #E2A53A;
		border-color: #E2A53A;
	}
	.ant-tag-success {
		color: #32A960;
		border-color: #32A960;
	}
	.ant-tag-processing {
		color: #1890FF;
		border-color: #329AFF;
	}
	.task-checkbox {
		margin: 0;
	}
	.ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover {
		background: #f1f1f1;
	}
	.ant-table-tbody > tr > td {
		border-bottom: 1px solid #f3f3f3;
	}
}

.ant-table-tbody>tr.ant-table-row.task-page-red-bg:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover {
	background: none;
}

.task-stepper {
	.ant-spin-nested-loading {
		width: 100%;
	}
}

.due-date {
	.ant-picker {
		width: 100%;
	}
}

.task-page {
	.ant-tag,
	.ant-tag a,
	.ant-tag a:hover {
		width: 6rem;
		text-align: center;
		border-radius: 3px;
	}
}

.task-detail-address {
	.ant-typography {
		color: #939393;
		font-size: 14px;
	}
}

.task-detail {
	.ant-col.ant-form-item-label {
		font-weight: 600;
	}

	.ant-form-item-label>label {
		color: #4b4b4b;
		font-size: 13px;
	}
}

span.anticon.ant-dropdown-menu-item-icon {
	color: #1990ff;
}

.task-frequency-container {
	.bottom-space {
		margin-bottom: 1rem;
	}

	.repeat-task-container {
		display: flex;
		align-items: flex-end;
	}

	.w-full {
		width: 100%;
	}

	.ant-radio-button-wrapper {
		width: 50%;
		text-align: center;
	}
}

.floating-panel.left-top.open {
	span.floating-panel-head-sub-title {
		font-size: 16px;
		font-weight: 700;
		color: #2d2d2d;
	}

	div#floating-scroll-panel {
		.ant-card-body {
			padding-top: 0;
		}
	}

	.ant-col.ant-col-24.task-detail-form {
		.ant-form-item {
			margin: 0 0 20px;
		}
	}

	.task-detail-form {
		button.ant-btn.ant-btn-primary.save {
			float: right;
		}
	}
}

.icon.icon-drivers.p-0.task-dropdown {
	padding: 0;
	color: #1990ff;
}


.floating-task-list {
	.cards-container {
		padding-right: 6px;
	}

	&.top {
		display: flex;
		align-items: center;
	}
}

.floating-task-list-menu {
	.icon {
		padding: 0;
	}
}

.ant-space.ant-space-vertical.floating-task-list-space,
.ant-space.ant-space-vertical.floating-driver-list-space {
	background: #e8e8e89e;
	padding: 10px 8px;
	border-top: 1px solid #e8e8e89e;
}

.floating-task-list-space {
	overflow-y: auto;
}

.ant-card.ant-card-bordered.ant-card-hoverable.task-card {
	border: 1px solid #d9d9d9b3;
	box-shadow: 0px 3px 10px -6px rgba(0, 0, 0, 0.22);
}

.tag-status {
	display: flex;
	justify-content: center;
}

.floating-panel.left-top.open {
	left: 4px;
	top: 4px;
}

.floating-panel.right-top.open {
	right: 4px;
	top: 4px;
}

.task-upload, .fuel-bill-upload {
	.footer {
		display: flex;
		column-gap: 10px;
		justify-content: flex-end;
		margin-top: 16px;
		border-top: 1px solid #eeeeee;
		padding-top: 15px;
	}

	.success span {
		font-weight: 600;
		color: #27b26a;
	}

	.failed span {
		font-weight: 600;
		color: #ed1b24;
	}

	.failed-file-container {
		.file {
			display: flex;
			column-gap: 8px;
			align-items: center;
			padding: 5px 14px;
			border: 1px solid #f2f2f2;
			border-radius: 5px;
			margin-top: 2px;
			background: #f2f2f2;
			color: #727272;

			.description {
				display: flex;
				flex-direction: column;
				width: 55rem;
			}

			.file-size {
				font-size: 12px;
				color: #808080bf;
			}

			.file-icon {
				font-size: 25px;
				color: #808080e3;
			}

			.file-download {
				width: -webkit-fill-available;
				text-align: right;

				.text {
					color: #8d8d8d;
				}
			}
		}
	}
}

.task-upload, .fuel-bill-upload {
	.description {
		color: #696969;
	}

	.due-date-title {
		margin-bottom: 15px;
		color: #3c3c3c;
	}
	.due-date-description {
		font-size: 12px;
		margin-bottom: 2px;
		color: #909090;
	}

	.statistics {
		display: flex;
		gap: 2rem;
		align-items: center;
		margin-bottom: 20px;

	}

	.total {
		font-size: 20px;
		align-items: center;
		display: grid;
		grid-template-columns: 8rem 3.5rem;
		font-weight: 600;

		.units {
			text-align: right;
		}
	}

	.success-failed {
		align-items: center;
		display: grid;
		grid-template-columns: 8rem 3.5rem;

		.t-description {
			display: flex;
			align-items: center;
		}

		.t-description.success {
			color: #27b26a;
		}

		.t-description.failed {
			color: #ed1b24;
		}

		.t-icon {
			margin-right: 5px;
		}

		.t-results {
			text-align: right;
		}
	}

	.failed-tasks-messsage {
		margin-top: 10px;
		font-size: 12px;
		color: #a9a9a9;

		.ft-m-box {
			display: flex;
			align-items: center;
			background: #1890ff14;
			color: #393939;
			padding: 10px 18px;
			gap: 27px;
			border: 1px solid #1890ff42;
			border-radius: 6px;
			margin-bottom: 11px;
		}

		.ft-m-icon {
			font-size: 22px;
			color: #1890ff;
		}

		.ft-m-title {
			font-weight: 600;
			font-size: 16px;
			margin-bottom: 5px;
			margin-left: -14px;
		}

		.ft-m-bullet {
			display: list-item;
			list-style-type: circle;
			margin-bottom: 4px;
			font-size: 13px;
		}

		.ft-m-bullet:first-child {
			margin-bottom: 0;
		}
	}
}

.ant-typography h3,
div.ant-typography-h3,
div.ant-typography-h3>textarea,
h3.ant-typography {
	font-size: 20px;
}

h4.ant-typography.task-confirmation-form-description {
	font-size: 16px;
	text-align: center;
}

.task-not-scheduled {
	margin-top: 18px;

	&-title {
		span {
			margin-left: 2px;
		}
	}

	&-content {
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 10px 16px;
		margin-bottom: 20px;
		margin-top: 8px;
		grid-row-gap: 10px;

		overflow-y: auto;
		overflow-x: hidden;
		max-height: 16vh;
		gap: 16px;
		padding: 12px;
		background: #f1f1f1;
	}

	&-checks {
		margin: 0 !important;
	}

	&-warning {
		display: flex;
		column-gap: 6px;
		padding: 0 4px;
		font-size: 12px;
		color: #3b3b3b91;
		font-weight: 500;

		.tns-icon {
			padding-top: 1px;
			font-size: 12px;
			color: #faad14
		}
	}
}

.task-assign-content {
	padding: 16px 25px;
	padding-top: 24px;
}

.tracked-phone-input {
	display: flex;
}

.phone {
	&-flag {
		margin-right: 4px;
	}

	&-label {
		width: 160px;
	}

	&-areacode {
		margin-right: 15px;
		width: 50px;
		text-align: right;
		padding-left: 9px;
	}
}

.ant-select-selector {
	.tracked-phone-input {
		display: flex;

		span.ant-typography.read-phone {
			color: #afafaf;
		}
		.phone {
			&-flag {
				margin-right: 4px;
			}
	
			&-label {
				width: 160px;
			}
	
			&-areacode {
				margin-right: 11px;
				width: auto;
				text-align: right;
			}
		}
	}
}
