.sensors {
	.addSensorButton {
		width: 100%;
		height: 175px;
		margin-top: 15px;
	}

	.sensorDivider {
		margin-bottom: 0px !important;
	}

	.ant-divider-horizontal.ant-divider-with-text-left:after, .ant-divider-horizontal.ant-divider-with-text-right:before, .ant-divider-horizontal.ant-divider-with-text-left:before {
		top: 8%;
	}

	.hidden {
		display: none;
	}
	.tankSamplesContainer {
		max-height: 150px;
		width: 100%;
		overflow-y: auto;
		.sample {
			padding-bottom: 4px;
			.delete {
				padding-top: 4px;
				font-size: 20px;
			}
		}
	}
	.addSampleContainer {
		.button {
			width: 200px;
		}
		padding-top: 5px;
		padding-left: 15px;
	}
	.headers {
		padding-bottom: 8px;
		.options {
			width: 89px;
			height: 30px;
		}
	}

	.tanks {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		flex-direction: row;
		width: 100%;

		.addSensorButton {
			height: -webkit-fill-available;
			min-height: 20rem;
			max-height: -webkit-fill-available;
		}
		&-button {
			width: 24rem;
		}
		&-card {
			width: 24rem;
		}
	}
}

.g2-tooltip {
	position: absolute;
	visibility: hidden;
	z-index: 8;
	background-color: rgba(255, 255, 255, 0.9);
	box-shadow: rgb(174, 174, 174) 0px 0px 10px;
	border-radius: 3px;
	color: rgb(87, 87, 87);
	font-size: 12px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Helvetica, 'PingFang SC',
		'Hiragino Sans GB', 'Microsoft YaHei', SimSun, sans-serif;
	line-height: 20px;
	padding: 10px 10px 6px;
	left: 312.014px;
	top: 135.014px;
}

.g2-tooltip-title {
	margin-bottom: 4px;
}
.g2-tooltip-list {
	margin: 0px;
	list-style-type: none;
	padding: 0px;
}

.tank-configuration-card {
  .voltage-scale {
    &-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-detail {
      border: 1px solid #e3e3e3;
      border-radius: 25px;
      padding: 1px 15px;
      width: fit-content;
    }
  }
  .tank-detail {
    display: grid;
    grid-template-columns: auto 8rem;

	&:hover {
		background: #f4f4f4;
		border-radius: 4px;
	}
  }
  .tank-detail.hidden {
    display: none;
  }
  .ant-divider-horizontal.ant-divider-with-text:before {
    width: 0;
  }
  .ant-divider-inner-text {
    padding-left: 0;
  }
  .ant-divider-horizontal.ant-divider-with-text:after, .ant-divider-horizontal.ant-divider-with-text:before {
    top: 0%;
    border-top: 1px solid #c5c5c5;
  }
}