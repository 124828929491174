.uploadImg{
    min-width: 150px;
    min-height: 150px;
    background-position: center;
    background-size: cover;
    border: 1px dashed #d9d9d9;
    .uploadIcon{
        position: absolute;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        font-size: 30px;
    }
}
