.mainLayout,
.emptyLayout {
	height: 100%;
	font-size: 14px;

	.trigger {
		font-size: 20px;
		padding: 0 16px;
		cursor: pointer;
		transition: color 0.3s;

		&:hover {
			color: #108ee9;
		}
	}
	.ant-dropdown-trigger {
		.ant-avatar {
			overflow: visible;
			margin-right: 5px;
		}
	}
	.text-right {
		text-align: right;
		line-height: normal;

		.ant-dropdown-trigger {
			padding: 0 16px;
			height: auto;
		}
	}
	.user-menu {
		font-size: 15px;
		line-height: 64px;
		padding: 0 16px;
		cursor: pointer;
		transition: color 0.3s;
		color: black;

		&hover {
			color: #108ee9;
		}
	}

	.logo {
		height: 32px;
		background: #333;
		border-radius: 6px;
		margin: 16px;
	}

	.ant-layout-sider-collapsed {
		.anticon {
			font-size: 16px;
		}
		.ant-menu-vertical {
			width: 100%;
		}
	}

	.header {
		background: #fff;
		padding: 0;
		box-shadow: 6px 5px 12px -6px #e7e7e78a;
		z-index: 1;

		.titles {
			display: flex;
			align-items: center;
		}
		.container {
			display: flex;
			align-items: flex-start;
			flex-direction: column;
		}
		.notice-icon {
			margin-right: 10px;
			padding: 0 16px;
		}
		.title {
			font-weight: bold;
			font-size: 18px;
			color: #00376b;
			line-height: normal;
			text-transform: capitalize;
			margin-top: 0px;
			height: 26px;
			z-index: 1;
		}
	}

	.content {
		margin: 10px 5px 5px;
		background: #fff;
		overflow: auto;
		margin-bottom: 0;
		margin-top: 0;
		.view {
			padding: 24px;
		}
		&.mobileContent {
			overflow: hidden;
			overflow-y: auto;
			height: '100%'
		}
		.photosView {
			padding: 0px 24px 0px 24px;
		}
		.actions {
			.ant-btn {
				padding: 4px 12px;
			}
		}
	}
	.mobileMenuIcon {
		font-size: 30px;
		padding: 5px;
		margin: 5px;
	}
}

.popMenu {
	margin: 0px 5px;
	.ant-popover-arrow {
		&:after {
			border-bottom-color: #001529 !important;
		}
	}
	.ant-popover-arrow {
		background: #001529 !important;
	}
	.ant-popover-inner {
		background: #001529 !important;
		.ant-popover-title,
		.side-icon,
		.nav-text {
			color: #c0c0c0 !important;
		}
		.ant-popover-inner-content {
			padding: 0px;
		}
		.navRow {
			padding: 10px;
			cursor: pointer;
			&.selectedMenu {
				background: #108ee9;
				.ant-popover-title,
				.side-icon,
				.nav-text {
					color: #fff !important;
				}
			}
		}
	}
}

.side-menu-overlay {
	.ant-dropdown-menu-submenu-arrow-icon {
		color: #ffffffa6 !important;
	}
}

.menu-button {
	padding: 0rem 0.5rem;
	padding-right: 0.3rem;
}

header.ant-layout-header.header {
	height: 58px;
}

.ant-row.ant-row-space-between.ant-row-middle {
	height: inherit;
}

span.anticon.anticon-bell.icon {
	height: auto;
	width: auto;
}

span.noticeButton.notice-icon {
	line-height: normal;
}

.mobile-store {
	color: #fff;
	text-decoration: underline;
	img {
		width: 80px;
	}
}
