.working-hours {
    display: flex;
    justify-content: center;

    .ant-form-item {
        margin: 0;
    }
    .working-hours-container {
        width: 30rem;
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding: 14px 0px;
    }
    .ant-col.ant-col-1.checks {
        margin-right: 10px;
    }

    .ant-picker.time-picker {
        width: 100%;
    }
    .ant-row.working-hours-warning {
        margin-top: -10px;
    }
    .ant-col.ant-col-17.warn-message {
        font-size: 12px;
        margin-left: 11px;
    }
}