.sim {
	.ant-spin-container {
		margin: 3px;
	}
}

.uploadContent {
	text-align: center;
	.uploadIcon {
		color: #52c41a;
		font-size: 72px;
	}
	.uploadFail {
		color: #ff4d4f;
		font-size: 72px;
	}
}

.upload-drag-remove {
	.ant-upload-drag {
		display: none !important;
	}
}

.device-advance-options {
	display: none !important;
}

.ant-upload.ant-upload-select-picture-card {
	margin-right: 0 !important;
	margin-bottom: 0 !important;
}
