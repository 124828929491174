$icon-color: #a5a5a5;

.team-members-progress-container {
	background: #f7f7f7;
	height: 100%;
}

.team-members-progress {
	height: 100%;
	padding: 0 2rem;
	padding-top: 2rem;
	background: #ffffff;

	.qty-cell {
		max-width: 130px;
	}

	.progress-toolbar {
		display: flex;
		justify-content: space-between;
		margin-bottom: 2rem;

		.search-team-members {
			width: 22rem;
			span.ant-input-affix-wrapper {
				border-radius: 6px 0 0 6px;
			}

			.ant-input-search
				> .ant-input-group
				> .ant-input-group-addon:last-child
				.ant-input-search-button:not(.ant-btn-primary) {
				border-radius: 0px 6px 6px 0px;
			}
		}
		.date-picker {
			width: 13rem;
		}
	}

	.progress-header {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		border-radius: 6px;
		align-items: center;
		color: #252525;
		font-weight: 500;
		padding: 0 18px;
		padding-right: 30px;

		.cell {
			flex: 1;
			min-width: 100px;
			height: -webkit-fill-available;
			text-align: center;
			border-radius: 4px;
			box-sizing: border-box;
			aspect-ratio: 18 / 1;
			align-content: center;
		}
		.cell:first-child {
			text-align: left;
		}
		.cell:last-child {
			text-align: end;
			flex: 0;
			min-width: 50px;
		}
	}

	.progress-list {
		display: flex;
		flex-direction: column;
		gap: 14px;
		overflow-y: auto;
		max-height: 80%;
	}

	.ant-spin-nested-loading {
		padding-top: 2px;
		overflow-y: auto;
		max-height: 80%;
		min-height: 75%;
	}

	.progress-list-footer {
		.total-list {
			display: flex;
			gap: 5px;
			color: darkgray;
			margin-top: 6px;
		}
	}

	.progress-card {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		background: #f6f6f6;
		padding: 10px 18px;
		border-radius: 6px;
		align-items: center;
		color: #272727;
		cursor: pointer;

		.cell {
			flex: 1;
			min-width: 100px;
			height: -webkit-fill-available;
			text-align: center;
			border-radius: 4px;
			box-sizing: border-box;
			aspect-ratio: 16 / 1;
			align-content: center;

			.last-reported {
				color: #8a8a8a;
				font-size: 10px;
			}
		}
		.percentage span {
			color: #979696;
			font-size: 12px;
			margin-left: 2px;
		}
		.name {
			text-transform: capitalize;

			.ellipsis {
				width: 100%;
				margin: 0;
			}
		}
		.cell:first-child {
			text-align: left;
		}
		.cell:last-child {
			text-align: end;
			flex: 0;
			min-width: 50px;
		}
	}

	.progress-card:hover {
		background: #dedede;
	}

	&.container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.ant-spin-nested-loading {
			width: 60%;
		}
		.progress-title-container {
			.progress-title {
				margin: 0;
				letter-spacing: 0.3;
				font-size: 12px;
				width: 100%;
			}
		}
		.progress-values {
			display: flex;
			flex-direction: row;
			width: 100%;
			align-items: center;
			.progress-bar {
				display: flex;
				justify-content: center;
				align-items: center;
				flex: 3;
			}
			.progress-bar-text {
				margin-left: 5px;
				font-size: 11px;
				display: flex;
				flex: 1;
			}
		}
	}
	&.capacity-alert-modal {
		.ant-modal-body {
			display: flex;
			height: 350px;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 30px;
		}
		.animation-section {
			flex: 2;
			position: relative;
			.animation {
				width: 180px;
				height: 180px;
				position: absolute;
				left: 50%;
				right: 50%;
				top: 50%;
				bottom: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.text-section {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 10px;
			.description {
				color: #000;
				font-size: 20px;
				font-style: normal;
				line-height: normal;
			}
			.secondary-description {
				font-size: 16px;
			}
		}
	}
	&.billing-modal {
		.ant-modal-body {
			display: flex;
			flex-direction: column;
			gap: 22px;
		}
		.input-section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			.seats-input {
				width: 30%;
			}
		}
		.billing-description-container {
			padding-top: 0.5rem;
			.billing-description {
				font-size: 0.84rem;
				color: #757575;
				word-break: break-all;
			}
		}
		.total-section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
}

.team-progress-detail {
	padding: 10px;
	padding-bottom: 0;
	padding-top: 20px;

	.col-left {
		padding-right: 5px;
	}
	.col-right {
		padding-left: 10px;
		height: initial;
	}
	.detail-header {
		gap: 10px;
		margin-bottom: 10px;

		.detail-header-name {
			font-size: 16px;
			font-weight: 800;
			color: #484848;
		}
		.detail-header-number {
			font-size: 14px;
			color: #878787;
			background: #f5f5f5;
			padding: 2px 8px;
			border-radius: 6rem;
			border: 1px solid #d9d9d9;
		}
		span.phone-icon {
			margin-right: 6px;
		}
		span.anticon.anticon-phone {
			rotate: 90deg;
		}
	}
	.detail-toolbar {
		width: 100%;
		justify-content: space-between;
		margin-bottom: 10px;
		padding-bottom: 8px;

		.button-filters {
			gap: 10px;
			height: 100%;

			.filter-select-button {
				cursor: pointer;
				padding: 2px 18px;
				border-radius: 10rem;
			}
			.inactive-selection {
				background: #f5f5f5;
				border: 1px solid #f5f5f5;
				color: #252525;
				height: 100%;
				display: flex;
				align-items: center;
			}
			.active-selection {
				color: #1890ff;
				border: 1px solid #1890ff;
				height: 100%;
				display: flex;
				align-items: center;
			}
			.shortcut {
				svg {
					transform: scale(-1, 1);
					color: #5c5c5c;
				}
			}
		}
	}
	.task-filter-panel {
		.tasks-panel-toolbar {
			padding: 4px 0px;
			justify-content: space-between;
			align-items: center;

			.task-count {
				font-size: 13px;
			}
			button.ant-btn.ant-btn-text.ant-btn-icon-only,
			.task-count {
				color: #b2b2b2;
			}
		}

		.tasks-list-container {
			padding-right: 14px;
			height: 30rem;
			overflow: auto;
			display: flex;
			justify-content: center;
			align-items: flex-start;

			.task-list {
				gap: 10px;
				justify-content: center;
				align-items: center;
			}
		}
		.task-container-empty {
			justify-content: center;
			align-items: center;
		}
	}
	.map-details {
		height: 100%;
		display: flex;
		flex-direction: column;

		.row-top {
			height: 100%;
		}
		.map-task-detail {
			width: 100%;
			height: 100%;
		}
		.ant-ribbon-wrapper {
			width: 100%;
		}
		.ant-ribbon.ant-ribbon-placement-end.description-card-ribbon {
			top: 16px;
			width: 7rem;
			display: flex;
			justify-content: center;
		}
		.description-card {
			height: 14rem;
			width: 100%;
			margin-bottom: 10px;

			.ant-tabs-content-holder {
				height: 150px;
				overflow: auto;
				padding-top: 5px;
				width: 90%;
			}
		}
	}
	.detail-task-overall {
		justify-content: space-between;
	}
	.description-card {
		padding: 0px 22px;
		border: 1px solid lightgray;
		border-radius: 6px;
		margin-top: 8px;

		.detail {
			.detail-icon {
				vertical-align: top;
				color: $icon-color;
				font-size: 11px;
				padding-top: 3px;
				padding-right: 10px;
			}
			.detail-type {
				width: 95px;
				vertical-align: top;
			}
			.detail-icon,
			.detail-type,
			.detail-description {
				padding-bottom: 10px;
			}
		}
		.contact {
			.contact-icon {
				color: $icon-color;
				font-size: 12px;
				padding-top: 2px;
				padding-right: 10px;
			}
			.contact-type {
				width: 75px;
			}
			.contact-icon,
			.contact-type,
			.contact-description {
				vertical-align: top;
				padding-bottom: 10px;
			}
		}
	}
	.ant-spin-nested-loading {
		width: 100%;
	}
}

.ant-modal.progress-modal {
	width: 72rem !important;
}

/* Full HD Desktops */
@media only screen and (min-width: 1920px) and (max-width: 3850px) {
	.team-members-progress {
		width: 70%;
		margin: auto;
	}
	.team-progress-detail {
		padding-bottom: 0;
		height: 90%;

		.detail-task-overall {
			height: 95%;
		}
	}
	.team-progress-detail .map-details .row-top {
		height: 100%;
	}
	.ant-modal.progress-modal {
		width: 95rem !important;
	}

	.team-progress-detail .task-filter-panel .tasks-list-container {
		height: 45rem;
	}
}

/* Big Laptops */
@media only screen and (min-width: 1440px) and (max-width: 1919px) {
}

/* Normal Laptops */
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
}

/* iPads (Tablets) */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Phones */
@media only screen and (max-width: 767px) {
}
