.announcements {
    background: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &-table {
        width: 100%;

        .ant-table-body {
            overflow-y: auto !important;
        }

        .status {
            text-align: center;
        }
        .status.draft {
            color: #ff4d4f;
            font-weight: 500;
        }
        span.post.icon {
            color: #afafaf;
            font-size: 14px;
        }
        .ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover {
            background: #f1f1f1;
        }
    }
    &-actions {
        button.ant-btn.ant-btn-text {
            padding: 0 4px;
        }
        button {
            span {
                height: auto;
                width: auto;
            }
        }
        .publish-button {
            span.anticon.anticon-send {
                color: #1890ff;
            }
        }
        .publish-button.send {
            span.post.icon {
                transform: rotate(310deg);
            }
        }
    }
    &-modal {
        margin-top: 2rem;

        .display-time {
            width: 100%;
        }
        .preview-message {
            font-size: 12px;
            color: #b1b1b1;
            text-align: center;
            line-height: normal;
            margin-bottom: -8px;
            margin-top: 14px;
        }
        .select-type {
            div#type {
                width: 100%;
            }
            label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
                width: 50%;
                text-align: center;
            }
        }
        .select-carrier {
            width: 100%;
        }
        .carriers-input {
            label {
                width: 100% !important;
            }
            .ant-col.ant-form-item-label {
                height: 25px;
            }
            .ant-row.ant-row-space-between {
                width: 100%;
                margin-bottom: 4px;
            }
            label.ant-checkbox-wrapper {
                justify-content: flex-end;
            }
        }
    }
    &-pills {
        justify-self: center;
        padding: 1px 8px;
        border-radius: 10rem;
        font-size: 12px;
        width: 100%;
        text-align: center;
    }
    &-pills.warning-pill {
        color: #F98A3B;
        background: #ffc49b40;
        border: 1px solid #f98a3b80;
    }
    &-pills.info-pill {
        color: #394A9C;
        background: #bbc7ff40;
        border: 1px solid #394a9c80;

    }
    &-view-more {
        .ant-modal-body {
            justify-items: center;
            font-size: 16px;
            text-align: center;
            padding-bottom: 35px;
        }
        &-icon {
            svg {
                height: auto;
                width: 90px;
            }
        }
        &-description {
            padding-top: 14px;
        }
    }
    &-action-button {
        width: 100%;
        margin-top: 18px;

        button.ant-btn.ant-btn-default.close-button {
            background-color: #2897FF;
            color: white;
            border: #2897FF;
            border-radius: 40px;
            padding: 0px 38px;
        }
    }
    &-view {
        .ant-modal-body {
            padding-bottom: 10px;
        }
        .ant-row {
            margin-bottom: 8px;
        }
        &-title {
            .ant-typography strong {
                color: #343434;
            }
        }
        &-type {
            .type-pills {
                width: 8rem;
            }
        }
        &-carriers {
            span.ant-tag {
                border-radius: 4px;
                padding: 0 10px;
                margin-bottom: 6px;
            }
            .carriers-tags {
                max-height: 9rem;
                overflow-y: auto;
            }
            .all-carriers-selected {
                font-size: 12px;
                font-style: italic;
                color: darkgray;
                padding-right: 10px;
            }
        }
    }

    button {
        .announcements-button-add {
            margin-right: 6px;
        }
    }

    td.ant-table-cell.announcements-title, th.ant-table-cell.announcements-title {
        display: flex;
        justify-content: center;
    }
}

.announcement-banner {
    .view-more {
        text-decoration: underline;
        text-transform: lowercase;
        text-wrap-mode: nowrap;
        padding-left: 10px;
    }
    .view-more:hover {
        cursor: pointer;
    }
    .ant-alert-message {
        color: #ffffff;
        font-weight: 500;
        font-size: 15px;
    }
    .ant-alert-description {
        color: #ffffff;
        font-weight: 500;
        font-size: 15px;
    }
    span.ant-typography.ant-typography-ellipsis.ant-typography-single-line.ant-typography-ellipsis-single-line {
        color: #ffffff;
    }
    .ant-row {
        justify-content: center;
        padding: 0px 12%;
    }
}

.announcement-banner.info-announcement, .announcement-banner.warning-announcement {
    border: 0;
    text-align: center;
    inline-size: max-content;
    width: 100%;
    display: flex;
}

.announcement-banner.info-announcement {
    background: #394A9C;
}

.announcement-banner.warning-announcement {
    background: #F98A3B;
}

.ant-modal.announcements-view {
    margin-top: 24px;
}

@media only screen and (max-width: 767px) {
    .ant-modal.announcements-view-more {
        width: 358px !important;
    }
    .announcements-view-more .ant-modal-body {
        border-radius: 10px;
    }
}


