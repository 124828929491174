.sort-tasks-modal {
    .ant-modal-body {
        padding-bottom: 8px;
        padding-top: 10px;
    }
    .list {
        user-select: none;
        display: flex;
        gap: 8px;
        min-height: 8rem;
        max-height: 24rem;
        overflow: auto;
    }
    .description {
        padding-bottom: 10px;
    }
    .ant-modal-footer {
        padding-right: 22px;
    }
    .text {
        width: 16rem;
    }
}

.dragged-tasks, .item-tasks {
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
    padding: 4px 10px;
    border-radius: 4px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
        font-weight: 700;
    }
    .address {
        font-size: 12px;
        color: gray;
    }
    .icons {
        font-size: 18px;
        padding-right: 10px;
        color: #ababab;
    }
}

.numbered {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    height: fit-content;

    .number {
        height: 51px;
        margin-bottom: 10px;
    }
}

.sort-view {
    .description {
        font-size: 14px;
        line-height: 1.5;
        padding: 10px 2px;
    }
    .list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        min-height: 12rem;
        max-height: 28rem;
        overflow: auto;
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        padding: 7px 0;
    }
    .item-tasks {
        .text {
            width: 16rem;
        }
    }
    .icons {
        color: #808080;
    }
}

.sort-card-container {
    background: transparent !important;
    border: 0 !important;
}

.item-tasks:hover {
    cursor: grab;
}

.dragged-tasks {
    z-index: 1000;
    background-color: #ffffff;
    .item-tasks {
        cursor: grabbing !important; 
        .text {
            width: 18rem;
        }
    }
}
