.schedule {
	.scheduleCard{
		margin: 15px 0;
		.ant-card-body{
			padding: 14px 24px;
		}
		.ant-card-head-title{
			text-transform: capitalize;
		}
		.tagContainer{
			height: 100px;
			border: 1px solid #e8e8e8;
			overflow-y: scroll;
			.tag{
				margin: 5px;
			}
			.ant-empty{
				margin: 12px 0;
			}
		}
	}
	.configIcon {
		color: #1890ff;
		cursor: pointer;
		&:hover{
			font-weight: bold;
		}
	}
	.allConfig{
		padding: 0 20px;
		.configIcon {
			padding-right: 5px;
		}
	}
	.deviceSelect, .eventSelect {
		padding: 10px 0 0 0;
	}
	.ant-spin-nested-loading {
		min-height: 100px;
		height: 100%;
	}
	.newGroupButton{
		width: 100%;
		height: 318px;
		margin-top: 15px;
	}
}
.daysContainer{
	padding: 0!important;
	.days{
		padding: 10px 5px;
		.day{
			text-align: center;
			text-transform: uppercase;
			border: 1px solid #a6adb4;
			color: #a6adb4;
			border-radius: 50%;
			color: rgb(166, 173, 180);
			width: 25px;
			height: 25px;
			display: inline-block;
			font-size: 8px;
			margin: 0 2px;
			font-weight: bold;
			cursor: pointer;
			span{
				margin: 25% 0;
				display: block;
			}
			&.active{
				border: 1px solid #52c41a;
				color: #52c41a;
			}
			&.selected{
				border: 1px solid #1890ff;
				color: #1890ff !important;
				box-shadow: 0px 0px 6px -1px #1990ff;
			}
			&:hover{
				.dayText{
					color: #1890ff;	
				}
			}
		}
	}
	&.form{
		text-align: center;
	}
}