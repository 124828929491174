.route-dashboard {
	text-align: center;
	margin: 0 auto;
	height: 100%;
	.dashboard-button {
		width: 208px;
		height: 204px;
		border-width: 3px;
		color: #9c9c9c;
		border-color: #1f59b9;

		&:hover {
			color: #15a4db;
			border-color: #15a4db;
			box-shadow: 0 4px 4px 0 #00000040;
		}
		.icon {
			font-size: 89px;
		}
		.label {
			font-size: 20px;
			font-weight: 700;
			white-space: normal;
		}
	}
}
