.collapsible-panel {
	border-radius: 6px;
	padding-top: 4px;
	display: flex;
	flex-direction: column;

	.tab-button {
		z-index: 10;
		height: 68px;
		width: 25px;
		position: absolute;
		top: 42%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		box-shadow: 3px 4px 4px rgb(0 0 0 / 15%);
		border-radius: 0px 6px 6px 0px;

		.collapse-icon {
			font-size: 18px;
		}
	}

	.tab-button.collapsed {
		animation: slide-out 0.9s;
		animation-fill-mode: forwards;
		right: -18px;

	}

	.tab-button.expanded {
		animation: slide-in 1s;
		animation-fill-mode: forwards;
		right: -25px;
	}

	.inner-content {
		padding: 15px 18px;
		overflow: hidden;
		position: relative;
		background: #ffffff;
		border-radius: 6px;
		margin-left: 10px;
	}

	.inner-content.collapsed {
		animation: slide-in-panel 1s;
		animation-fill-mode: forwards;
	}

	.inner-content.expanded {
		animation: slide-out-panel 1s;
		animation-fill-mode: forwards;
		right: 10px;
	}
}

@keyframes slide-out {
	from {
		left: 452px;
	}

	to {
		left: -7px;
	}
}

@keyframes slide-in {
	from {
		left: -10px;
	}

	to {
		left: 452px;
	}
}


@keyframes slide-out-panel {
	from {
		right: 480px;
	}

	to {
		right: 10px;
	}
}

@keyframes slide-in-panel {
	from {
		right: 100px;
	}

	to {
		right: 480px;
	}
}