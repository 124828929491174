.modal {
    &-switch {
        &.title {
            color: #3e3e3e;
            font-size: 15px;
            font-weight: 500;
        }
        &.subtitle {
            color: #808080;
            font-size: 12px;
        }
        &.title-disabled {
            color: #8f8f8f;
        }
        &.subtitle-disabled {
            color: #bfbfbf;
        }
    }
}