.device-form{
	&-name {
		display: flex;
		align-items: flex-end;
	}
	&-enabled {
		div#enabled {
			width: 100%;
		}
		label.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item {
			width: 50%;
			text-align: center;
			padding: 0;
		}
	}
}
.deviceIcon, .ant-select-item-option-content{
	.svgIcon{
		margin-right: 5px;
		width: 30px;
	}
	.eventSvgIcon{
		width: 30px;
		margin-right: 5px;
		height: 25px;
	}
	.optionIcon {
		width: 23px;
	}
	.ant-select-selection-search-input{
		padding-left: 25px!important;
	}
}
.eventSvgIcon{
	width: 25px;
	margin-right: 5px;
	height: 25px;
}
.inputForm{
	width: 100%;
	margin-top: 4px;
}
.ant-row-flex-middle{
	align-items: end!important;
}
.addDeviceForm {
	&>span {
		margin-bottom: 20px;
	}
}

.iconColumn{
    padding-top: 0px;
    padding-bottom: 0px;
	.icon {
		font-size: 28px;
	}
}

.labelStyle{
	font-weight: bold;
}

.itemStyle{
	padding-left: 7px;
}

.formItemStyle {
	margin-bottom: 0!important;
	.ant-form-item-control{
		line-height: 30px;
	}
	.ant-picker-small{
		width: 100%;
	}
}