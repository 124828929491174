.gm-style .gm-style-iw-c {
    left: 44px;
}

#mapContainer_main {
    width: 100%;
    height: 100% !important;

    .gm-style .gm-style-iw-c {
        left: 2px;
        padding: 0;
    }
}

#mapContainer_routeMonitor {
    height: 100% !important;
}

.gm-style .gm-style-iw-c, .gm-style .gm-style-iw-tc {
    top: -45px;
    left: 2px;
}

button.gm-ui-hover-effect {
    outline: none;

    display: flex !important;
    margin: 6px !important;
    top: 0px !important;
    right: 5px !important;
    width: 30px !important;
    height: 30px !important;
    justify-content: center !important;
    align-items: center !important;

    span {
        width: 20px !important;
        height: 20px !important;
        margin: 0 !important;
    }
}

.gm-style iframe + div {
    border: none!important;
}

.gm-style-iw-d {
    margin-bottom: -6px;
    overflow: auto !important;
    padding: 12px 14px;
    padding-top: 2px;

    .content {
        .ant-col.ant-col-24 {
            max-height: 16rem;
            overflow: auto;
        }
    }
}

.gm-style-iw.gm-style-iw-c {
    .header {
        display: flex;
        justify-content: space-between;
        display: flex;
        justify-content: space-between;
        padding: 4px 2px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #f0f0f1;
        box-shadow: none;
        align-items: center;
        margin-bottom: 10px;
    }
}
