.login-page {
	min-height: 100vh;
	background: #e4e9f2;
	display: flex;
	justify-content: center;
	align-items: center;
	&-card {
		border-radius: 12px;
		.ant-card-body {
			padding: 20px;
		}
		&-body {
			display: flex;
			align-items: center;
			&-panel-right {
				width: 284px;

				&-logo,
				&-website,
				&-service,
				&-instructions,
				&-form,
				&-footer {
					padding: 0 30px;
					font-family: Roboto;
				}

				&-logo {
					text-align: center;
					height: 170px;
					img {
						height: 170px;
					}
				}
				&-website {
					text-align: center;
				}
				&-disclaimer,
				&-service {
					text-align: center;
					color: #354c9b !important;
					&-divider {
						border-left: 1px solid #354c9b;
					}
				}
				&-disclaimer {
					font-size: 18px;
					display: flex;
					justify-content: space-evenly;
					span {
						font-weight: bold;
					}
				}
				&-service {
					font-size: 16px;
				}
				&-instructions {
					text-align: center;
					font-weight: bold;
					font-size: 10px;
					padding-top: 15px;
					padding-bottom: 5px;
				}
				&-form {
					width: 284px;
					.ant-form-item {
						margin: 0 0 15px;
						&.login-page-card-body-panel-right-form-password {
							margin: 0;
						}
					}
					&-third-party {
						padding: 10px !important;
						width: 100%;
						display: flex;
						justify-content: center;
						min-width: 130px;
						max-width: 375px;
						height: 40px;
					}
					&-forgot {
						font-size: 10px;
						&::after {
							content: '?';
						}
					}
					&-or {
						text-align: center;
					}
					&-accont-actions {
						display: flex;
						justify-content: space-between;
					}
				}
				&-footer {
					padding: 20px 0 0;
					display: flex;
					justify-content: center;
				}
			}
		}

		/* LG | XL | XXL*/
		@media only screen and (min-width: 992px) {
			width: 731px;
		}

		/* MD */
		@media only screen and (min-width: 768px) and (max-width: 991px) {
			width: 660px;
		}

		/* XS | SM */
		@media only screen and (max-width: 767px) {
			width: 419px;

			&-body {
				justify-content: center;
				&-lottie {
					display: none;
				}
			}
		}
	}
}

.resetPassword {
	height: 100%;
	.resetform {
		margin: auto;
	}
	.login-form-button {
		width: 100%;
	}
}
