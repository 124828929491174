.sideMenu{
        overflow: auto;
        height: 100vh;
        left: 0;
        top: 0;
        bottom: 0;
}
.side-icon{
    padding-right: 10px;
    & + .nav-text{
    	margin-left: 5px;
    }
}

.ant-menu-inline-collapsed{
	width: 100%!important;
}

aside.ant-layout-sider.ant-layout-sider-dark.sideMenu {
    min-width: 250px !important;
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-zero-width.sideMenu {
    min-width: 0px !important;
}

span.side-icon.menu-icon-color.ant-menu-item-icon {
    color: #ffffffcc;
}

.sidebar-section {
    color: #ffffffa1;
}