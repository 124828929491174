.groups {
	width: 100%;
	.groupCard {
		margin: 15px 0;
		.footer {
			bottom: 30px;
			right: 0;
			font-weight: bold;
			position: absolute;
			text-align: right;
		}
		.body {
			text-align: center;
		}
		.icon {
			font-size: 15px;
			margin-right: 5px;
		}
	}
	.newGroupButton {
		width: 100%;
		height: 178px;
		margin-top: 15px;
	}
}
.groupModal {
	.footer {
		margin: 0;
		.ant-divider {
			margin-top: 15px;
			margin-bottom: 10px;
		}
		.actions {
			width: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}
}
