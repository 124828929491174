.trips{
    .cardIcon {
        font-size: 64px;
        text-align: center;
        display: block;
    }
    .tripCard{
        height: 240px;
        margin: 15px 0;
    }
    .labels {
        font-size: 13px;
        text-align: center;
    }
    .addTripButton {
        width: 100%;
        height: 240px;
        margin-top: 15px;
    }
}